import React from 'react'
import { Dropdown, Input } from '../../../../../components'
import { FORM_FIELDS } from '../../../../../constants'
import styled from 'styled-components'
import { useInputProps } from '../../../../../hooks'
import {
    COUNTRIES_OPTIONS,
    DEFAULT_COUNTRY,
} from '../../../../../constants/countries'

const SFormWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SInputRow = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.lg}) {
        flex-direction: column;
    }
`

const SAddressWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-grow: 1;
    flex-basis: 100%;
`

const SPostCodeInput = styled(Input)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-basis: 33%;
    }
`

export const BusinessClient = () => {
    const getInputProps = useInputProps()

    return (
        <SFormWrapper>
            <Input
                {...getInputProps(FORM_FIELDS.email)}
                placeholder="Adres email*"
                hint="Na ten e-mail wyślemy informacje o zamówieniu."
            />
            <Dropdown
                {...getInputProps(FORM_FIELDS.country)}
                placeholder="Wybierz kraj z listy*"
                defaultValue={DEFAULT_COUNTRY}
                options={COUNTRIES_OPTIONS}
            />
            <Input {...getInputProps(FORM_FIELDS.nip)} placeholder="NIP*" />
            <Input
                {...getInputProps(FORM_FIELDS.companyName)}
                placeholder="Firma*"
            />
            <SInputRow>
                <Input
                    {...getInputProps(FORM_FIELDS.street)}
                    placeholder="Ulica*"
                />
                <SAddressWrapper>
                    <Input
                        {...getInputProps(FORM_FIELDS.houseNumber)}
                        placeholder="Numer domu*"
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.flatNumber)}
                        placeholder="Numer lokalu"
                    />
                </SAddressWrapper>
            </SInputRow>
            <SInputRow>
                <SPostCodeInput
                    {...getInputProps(FORM_FIELDS.postCode)}
                    placeholder="Kod pocztowy*"
                />
                <Input
                    {...getInputProps(FORM_FIELDS.city)}
                    placeholder="Miejscowość*"
                />
            </SInputRow>
            <Input
                {...getInputProps(FORM_FIELDS.phoneNumber)}
                placeholder="Telefon"
            />
        </SFormWrapper>
    )
}
