import React from 'react'
import styled, { css } from 'styled-components'

const SModalBackdrop = styled.div`
    top: 0;
    right: 0;
    background-color: ${({ theme: { colors } }) => colors.primary_opaque};
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 100000;
    ${({ visuallyHidden }) =>
        visuallyHidden &&
        css`
            display: none;
        `};

    ${({ fixed }) =>
        fixed
            ? css`
                  position: fixed;
                  width: 100vw;
                  height: 100vh;
              `
            : css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
              `}
`

const SModal = styled.div`
    border-radius: 10px;
    background-color: ${({ theme: { colors } }) => colors.light};
    padding: 15px 24px;
    max-width: 90%;
    position: relative;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        padding: 24px 40px;
        max-width: 400px;
    }
`

const SCloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
`

export const Popup = ({
    children,
    className,
    visuallyHidden,
    fixed,
    withCloseButton,
    onClose,
}) => {
    return (
        <SModalBackdrop visuallyHidden={visuallyHidden} fixed={fixed}>
            <SModal className={className}>
                {withCloseButton && (
                    <SCloseButton type="button" onClick={onClose}>
                        &#x2715;
                    </SCloseButton>
                )}
                {children}
            </SModal>
        </SModalBackdrop>
    )
}
