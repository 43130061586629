import React, { useEffect, useState } from 'react'
import { SectionWrapper } from '../../components'
import { Checkbox } from '../../../../components'
import { FORM_FIELDS } from '../../../../constants'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const SCheckboxWrapper = styled.div``

const SInnerWrapper = styled.div`
    padding: 20px 0px 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const SBold = styled.span`
    display: block;
    font-weight: 600;
`

const SLink = styled.a`
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.link};
`

const CHECKBOXES = [
    FORM_FIELDS.marketingPermission,
    FORM_FIELDS.termsAndConditions,
    FORM_FIELDS.personalisedService,
    FORM_FIELDS.confidentialityStatement,
]

export const PermissionsSection = () => {
    const { setValue, trigger, register, formState, watch } = useFormContext()
    const [allSelected, setAllSelected] = useState(false)

    const selectedValues = watch(CHECKBOXES)

    useEffect(() => {
        const allCheckboxesSelected = !selectedValues.some((value) => !value)

        if (allCheckboxesSelected && !allSelected) setAllSelected(true)
        else if (!allCheckboxesSelected && allSelected) setAllSelected(false)
    }, [selectedValues])

    const selectAll = (e) => {
        setAllSelected(e.target.checked)
        if (e.target.checked) {
            CHECKBOXES.forEach((field) => {
                setValue(field, true)
            })
            trigger(CHECKBOXES)
        }
    }

    return (
        <SectionWrapper title="Zgody formalne">
            <SCheckboxWrapper>
                <Checkbox
                    id="select_all"
                    label="Zaznacz wszystkie"
                    name="select_all"
                    checked={allSelected}
                    onChange={selectAll}
                />
                <SInnerWrapper>
                    <Checkbox
                        id="marketingCheckbox"
                        label="Chcę otrzymywać na podany adres e-mail oraz numer telefonu informacje o limitowanych okazjach, najlepszych promocjach i kody rabatowe"
                        {...register(FORM_FIELDS.marketingPermission)}
                        onChange={(e) => {
                            setValue(
                                FORM_FIELDS.marketingPermission,
                                e.target.checked
                            )
                        }}
                    />
                    {/* TODO: terms and conditions link */}
                    <Checkbox
                        id="termsAndConditionsCheckbox"
                        label={
                            <>
                                Oświadczam, że zapoznałem(-am) się i akceptuję
                                treść{' '}
                                <SLink
                                    href="/terms-and-conditions.pdf"
                                    target="blank"
                                >
                                    regulaminu
                                </SLink>
                                .*
                            </>
                        }
                        {...register(FORM_FIELDS.termsAndConditions)}
                        error={
                            formState.errors?.[FORM_FIELDS.termsAndConditions]
                                ?.message
                        }
                        onChange={(e) => {
                            setValue(
                                FORM_FIELDS.termsAndConditions,
                                e.target.checked
                            )
                            trigger(FORM_FIELDS.termsAndConditions)
                        }}
                    />
                    <Checkbox
                        id="personalisedServiceCheckbox"
                        label={
                            <>
                                Oświadczam, że mam świadomość iż jest to
                                personalizowana usługa i nie przysługuje mi
                                prawo do odstąpienia od umowy zawartej na
                                odległość.*
                                <SBold>
                                    W cenie zakupu ProTranskrypcje.pl oferuje do
                                    2 darmowych poprawek gotowego stenogramu, po
                                    konsultacji z klientem.
                                </SBold>
                            </>
                        }
                        error={
                            formState.errors?.[FORM_FIELDS.personalisedService]
                                ?.message
                        }
                        {...register(FORM_FIELDS.personalisedService)}
                        onChange={(e) => {
                            setValue(
                                FORM_FIELDS.personalisedService,
                                e.target.checked
                            )
                            trigger(FORM_FIELDS.personalisedService)
                        }}
                    />
                    <Checkbox
                        id="confidentialityStatementCheckbox"
                        label="Chcę otrzymać oświadczenie o zachowaniu poufności od ProTranskrypcje.pl"
                        {...register(FORM_FIELDS.confidentialityStatement)}
                        onChange={(e) => {
                            setValue(
                                FORM_FIELDS.confidentialityStatement,
                                e.target.checked
                            )
                        }}
                    />
                </SInnerWrapper>
            </SCheckboxWrapper>
        </SectionWrapper>
    )
}
