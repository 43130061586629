import React from 'react'
import { SectionWrapper } from '../../components'
import { Controller, useFormContext } from 'react-hook-form'
import {
    FORM_FIELDS,
    COMPLETION_TIME,
    COMPLETION_TIME_LABEL,
} from '../../../../constants'
import { RadioButton } from '../../../../components'
import styled from 'styled-components'

const SOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-direction: row;
        gap: 20px;
    }
`

export const CompletionTimeSelect = () => {
    const { control } = useFormContext()

    return (
        <SectionWrapper title="Wybierz czas realizacji">
            <Controller
                control={control}
                name={FORM_FIELDS.completionTime}
                render={({ field: { onChange, value } }) => {
                    return (
                        <SOptionsWrapper>
                            {[
                                COMPLETION_TIME.express,
                                COMPLETION_TIME.standard,
                            ].map((option) => (
                                <RadioButton
                                    id={option}
                                    name={FORM_FIELDS.completionTime}
                                    label={COMPLETION_TIME_LABEL[option]}
                                    value={option}
                                    checked={value === option}
                                    onChange={onChange}
                                />
                            ))}
                        </SOptionsWrapper>
                    )
                }}
            />
        </SectionWrapper>
    )
}
