export const theme = {
    colors: {
        primary: '#7952B3',
        secondary: '#1FA088',
        gray_1: '#EEECF1',
        gray_2: '#E4E0E6',
        gray_3: '#7B7B7B',
        secondary_light: '#D7F4E5',
        secondary_darker: '#1FA088',
        secondary_dark: '#1A8D78',
        primary_darker: '#67429F',
        primary_dark: '#432670',
        dark: '#242424',
        light: '#ffffff',
        error: '#D13239',
        error_lighter: '#D1323921',
        error_darker: '#b3282e',
        gray_1_lighter: '#EEECF180',
        primary_opaque: '#31106869',
        link: '#187ABB',
        secondary_opaque: '#1FA08824',
    },
    breakpoints: {
        md: '880px',
        lg: '1200px',
    },
}
