import React from 'react'
import { Input, Dropdown } from '../../../../../components'
import { CLIENT_TYPE, FORM_FIELDS } from '../../../../../constants'
import styled from 'styled-components'
import { useInputProps } from '../../../../../hooks'
import { useFormContext } from 'react-hook-form'
import {
    COUNTRIES_OPTIONS,
    DEFAULT_COUNTRY,
} from '../../../../../constants/countries'

const SFormWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SInputRow = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.lg}) {
        flex-direction: column;
    }
`

const SAddressWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-grow: 1;
    flex-basis: 100%;
`

const SPostCodeInput = styled(Input)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-basis: 33%;
    }
`

export const DifferentInvoiceData = () => {
    const { watch } = useFormContext()
    const getInputProps = useInputProps()

    const clientType = watch(FORM_FIELDS.clientType)

    return (
        <SFormWrapper>
            {clientType === CLIENT_TYPE.business ? (
                <>
                    <Dropdown
                        {...getInputProps(FORM_FIELDS.buyerCountry)}
                        placeholder="Wybierz kraj z listy*"
                        defaultValue={DEFAULT_COUNTRY}
                        options={COUNTRIES_OPTIONS}
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerNip)}
                        placeholder="NIP*"
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerCompanyName)}
                        placeholder="Firma/Podmiot*"
                    />
                </>
            ) : (
                <SInputRow>
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerName)}
                        placeholder="Imię*"
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerLastName)}
                        placeholder="Nazwisko*"
                    />
                </SInputRow>
            )}

            <SInputRow>
                <Input
                    {...getInputProps(FORM_FIELDS.buyerStreet)}
                    placeholder="Ulica*"
                />
                <SAddressWrapper>
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerHouseNumber)}
                        placeholder="Numer domu*"
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.buyerFlatNumber)}
                        placeholder="Numer lokalu"
                    />
                </SAddressWrapper>
            </SInputRow>
            <SInputRow>
                <SPostCodeInput
                    {...getInputProps(FORM_FIELDS.buyerPostCode)}
                    placeholder="Kod pocztowy*"
                />
                <Input
                    {...getInputProps(FORM_FIELDS.buyerCity)}
                    placeholder="Miejscowość*"
                />
            </SInputRow>
        </SFormWrapper>
    )
}
