export const DEFAULT_COUNTRY = {
    label: 'Polska',
    name_en: 'Poland',
    value: 'PL',
}

export const COUNTRIES_OPTIONS = [
    { label: 'Afganistan', name_en: 'Afghanistan', value: 'AF' },
    { label: 'Albania', name_en: 'Albania', value: 'AL' },
    { label: 'Algieria', name_en: 'Algeria', value: 'DZ' },
    { label: 'Andora', name_en: 'Andorra', value: 'AD' },
    { label: 'Angola', name_en: 'Angola', value: 'AO' },
    { label: 'Anguilla', name_en: 'Anguilla', value: 'AI' },
    { label: 'Antarktyka', name_en: 'Antarctica', value: 'AQ' },
    { label: 'Antigua i Barbuda', name_en: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Arabia Saudyjska', name_en: 'Saudi Arabia', value: 'SA' },
    { label: 'Argentyna', name_en: 'Argentina', value: 'AR' },
    { label: 'Armenia', name_en: 'Armenia', value: 'AM' },
    { label: 'Aruba', name_en: 'Aruba', value: 'AW' },
    { label: 'Australia', name_en: 'Australia', value: 'AU' },
    { label: 'Austria', name_en: 'Austria', value: 'AT' },
    { label: 'Azerbejdżan', name_en: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamy', name_en: 'Bahamas', value: 'BS' },
    { label: 'Bahrajn', name_en: 'Bahrain', value: 'BH' },
    { label: 'Bangladesz', name_en: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', name_en: 'Barbados', value: 'BB' },
    { label: 'Belgia', name_en: 'Belgium', value: 'BE' },
    { label: 'Belize', name_en: 'Belize', value: 'BZ' },
    { label: 'Benin', name_en: 'Benin', value: 'BJ' },
    { label: 'Bermudy', name_en: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', name_en: 'Bhutan', value: 'BT' },
    { label: 'Białoruś', name_en: 'Belarus', value: 'BY' },
    {
        label: 'Boliwia',
        name_en: 'Bolivia, Plurinational State of',
        value: 'BO',
    },
    {
        label: 'Bonaire, Sint Eustatius i Saba',
        name_en: 'Bonaire, Sint Eustatius and Saba',
        value: 'BQ',
    },
    {
        label: 'Bośnia i Hercegowina',
        name_en: 'Bosnia and Herzegovina',
        value: 'BA',
    },
    { label: 'Botswana', name_en: 'Botswana', value: 'BW' },
    { label: 'Brazylia', name_en: 'Brazil', value: 'BR' },
    { label: 'Brunei', name_en: 'Brunei Darussalam', value: 'BN' },
    {
        label: 'Brytyjskie Terytorium Oceanu Indyjskiego',
        name_en: 'British Indian Ocean Territory',
        value: 'IO',
    },
    {
        label: 'Brytyjskie Wyspy Dziewicze',
        name_en: 'Virgin Islands, British',
        value: 'VG',
    },
    { label: 'Bułgaria', name_en: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', name_en: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', name_en: 'Burundi', value: 'BI' },
    { label: 'Chile', name_en: 'Chile', value: 'CL' },
    { label: 'Chiny', name_en: 'China', value: 'CN' },
    { label: 'Chorwacja', name_en: 'Croatia', value: 'HR' },
    { label: 'Curaçao', name_en: 'Curaçao', value: 'CW' },
    { label: 'Cypr', name_en: 'Cyprus', value: 'CY' },
    { label: 'Czad', name_en: 'Chad', value: 'TD' },
    { label: 'Czarnogóra', name_en: 'Montenegro', value: 'ME' },
    { label: 'Czechy', name_en: 'Czech Republic', value: 'CZ' },
    {
        label: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
        name_en: 'United States Minor Outlying Islands',
        value: 'UM',
    },
    { label: 'Dania', name_en: 'Denmark', value: 'DK' },
    {
        label: 'Demokratyczna Republika Konga',
        name_en: 'Congo, the Democratic Republic of the',
        value: 'CD',
    },
    { label: 'Dominika', name_en: 'Dominica', value: 'DM' },
    { label: 'Dominikana', name_en: 'Dominican Republic', value: 'DO' },
    { label: 'Dżibuti', name_en: 'Djibouti', value: 'DJ' },
    { label: 'Egipt', name_en: 'Egypt', value: 'EG' },
    { label: 'Ekwador', name_en: 'Ecuador', value: 'EC' },
    { label: 'Erytrea', name_en: 'Eritrea', value: 'ER' },
    { label: 'Estonia', name_en: 'Estonia', value: 'EE' },
    { label: 'Etiopia', name_en: 'Ethiopia', value: 'ET' },
    { label: 'Falklandy', name_en: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Fidżi', name_en: 'Fiji', value: 'FJ' },
    { label: 'Filipiny', name_en: 'Philippines', value: 'PH' },
    { label: 'Finlandia', name_en: 'Finland', value: 'FI' },
    { label: 'Francja', name_en: 'France', value: 'FR' },
    {
        label: 'Francuskie Terytoria Południowe i Antarktyczne',
        name_en: 'French Southern Territories',
        value: 'TF',
    },
    { label: 'Gabon', name_en: 'Gabon', value: 'GA' },
    { label: 'Gambia', name_en: 'Gambia', value: 'GM' },
    {
        label: 'Georgia Południowa i Sandwich Południowy',
        name_en: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
    },
    { label: 'Ghana', name_en: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', name_en: 'Gibraltar', value: 'GI' },
    { label: 'Grecja', name_en: 'Greece', value: 'GR' },
    { label: 'Grenada', name_en: 'Grenada', value: 'GD' },
    { label: 'Grenlandia', name_en: 'Greenland', value: 'GL' },
    { label: 'Gruzja', name_en: 'Georgia', value: 'GE' },
    { label: 'Guam', name_en: 'Guam', value: 'GU' },
    { label: 'Guernsey', name_en: 'Guernsey', value: 'GG' },
    { label: 'Gujana Francuska', name_en: 'French Guiana', value: 'GF' },
    { label: 'Gujana', name_en: 'Guyana', value: 'GY' },
    { label: 'Gwadelupa', name_en: 'Guadeloupe', value: 'GP' },
    { label: 'Gwatemala', name_en: 'Guatemala', value: 'GT' },
    { label: 'Gwinea Bissau', name_en: 'Guinea-Bissau', value: 'GW' },
    { label: 'Gwinea Równikowa', name_en: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Gwinea', name_en: 'Guinea', value: 'GN' },
    { label: 'Haiti', name_en: 'Haiti', value: 'HT' },
    { label: 'Hiszpania', name_en: 'Spain', value: 'ES' },
    { label: 'Holandia', name_en: 'Netherlands', value: 'NL' },
    { label: 'Honduras', name_en: 'Honduras', value: 'HN' },
    { label: 'Hongkong', name_en: 'Hong Kong', value: 'HK' },
    { label: 'Indie', name_en: 'India', value: 'IN' },
    { label: 'Indonezja', name_en: 'Indonesia', value: 'ID' },
    { label: 'Irak', name_en: 'Iraq', value: 'IQ' },
    { label: 'Iran', name_en: 'Iran, Islamic Republic of', value: 'IR' },
    { label: 'Irlandia', name_en: 'Ireland', value: 'IE' },
    { label: 'Islandia', name_en: 'Iceland', value: 'IS' },
    { label: 'Izrael', name_en: 'Israel', value: 'IL' },
    { label: 'Jamajka', name_en: 'Jamaica', value: 'JM' },
    { label: 'Japonia', name_en: 'Japan', value: 'JP' },
    { label: 'Jemen', name_en: 'Yemen', value: 'YE' },
    { label: 'Jersey', name_en: 'Jersey', value: 'JE' },
    { label: 'Jordania', name_en: 'Jordan', value: 'JO' },
    { label: 'Kajmany', name_en: 'Cayman Islands', value: 'KY' },
    { label: 'Kambodża', name_en: 'Cambodia', value: 'KH' },
    { label: 'Kamerun', name_en: 'Cameroon', value: 'CM' },
    { label: 'Kanada', name_en: 'Canada', value: 'CA' },
    { label: 'Katar', name_en: 'Qatar', value: 'QA' },
    { label: 'Kazachstan', name_en: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenia', name_en: 'Kenya', value: 'KE' },
    { label: 'Kirgistan', name_en: 'Kyrgyzstan', value: 'KG' },
    { label: 'Kiribati', name_en: 'Kiribati', value: 'KI' },
    { label: 'Kolumbia', name_en: 'Colombia', value: 'CO' },
    { label: 'Komory', name_en: 'Comoros', value: 'KM' },
    { label: 'Kongo', name_en: 'Congo', value: 'CG' },
    { label: 'Korea Południowa', name_en: 'Korea, Republic of', value: 'KR' },
    {
        label: 'Korea Północna',
        name_en: "Korea, Democratic People's Republic of",
        value: 'KP',
    },
    { label: 'Kostaryka', name_en: 'Costa Rica', value: 'CR' },
    { label: 'Kuba', name_en: 'Cuba', value: 'CU' },
    { label: 'Kuwejt', name_en: 'Kuwait', value: 'KW' },
    { label: 'Laos', name_en: "Lao People's Democratic Republic", value: 'LA' },
    { label: 'Lesotho', name_en: 'Lesotho', value: 'LS' },
    { label: 'Liban', name_en: 'Lebanon', value: 'LB' },
    { label: 'Liberia', name_en: 'Liberia', value: 'LR' },
    { label: 'Libia', name_en: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', name_en: 'Liechtenstein', value: 'LI' },
    { label: 'Litwa', name_en: 'Lithuania', value: 'LT' },
    { label: 'Luksemburg', name_en: 'Luxembourg', value: 'LU' },
    { label: 'Łotwa', name_en: 'Latvia', value: 'LV' },
    {
        label: 'Macedonia',
        name_en: 'Macedonia, the former Yugoslav Republic of',
        value: 'MK',
    },
    { label: 'Madagaskar', name_en: 'Madagascar', value: 'MG' },
    { label: 'Majotta', name_en: 'Mayotte', value: 'YT' },
    { label: 'Makau', name_en: 'Macao', value: 'MO' },
    { label: 'Malawi', name_en: 'Malawi', value: 'MW' },
    { label: 'Malediwy', name_en: 'Maldives', value: 'MV' },
    { label: 'Malezja', name_en: 'Malaysia', value: 'MY' },
    { label: 'Mali', name_en: 'Mali', value: 'ML' },
    { label: 'Malta', name_en: 'Malta', value: 'MT' },
    {
        label: 'Mariany Północne',
        name_en: 'Northern Mariana Islands',
        value: 'MP',
    },
    { label: 'Maroko', name_en: 'Morocco', value: 'MA' },
    { label: 'Martynika', name_en: 'Martinique', value: 'MQ' },
    { label: 'Mauretania', name_en: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', name_en: 'Mauritius', value: 'MU' },
    { label: 'Meksyk', name_en: 'Mexico', value: 'MX' },
    {
        label: 'Mikronezja',
        name_en: 'Micronesia, Federated States of',
        value: 'FM',
    },
    { label: 'Mjanma', name_en: 'Myanmar', value: 'MM' },
    { label: 'Mołdawia', name_en: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monako', name_en: 'Monaco', value: 'MC' },
    { label: 'Mongolia', name_en: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', name_en: 'Montserrat', value: 'MS' },
    { label: 'Mozambik', name_en: 'Mozambique', value: 'MZ' },
    { label: 'Namibia', name_en: 'Namibia', value: 'NA' },
    { label: 'Nauru', name_en: 'Nauru', value: 'NR' },
    { label: 'Nepal', name_en: 'Nepal', value: 'NP' },
    { label: 'Niemcy', name_en: 'Germany', value: 'DE' },
    { label: 'Niger', name_en: 'Niger', value: 'NE' },
    { label: 'Nigeria', name_en: 'Nigeria', value: 'NG' },
    { label: 'Nikaragua', name_en: 'Nicaragua', value: 'NI' },
    { label: 'Niue', name_en: 'Niue', value: 'NU' },
    { label: 'Norfolk', name_en: 'Norfolk Island', value: 'NF' },
    { label: 'Norwegia', name_en: 'Norway', value: 'NO' },
    { label: 'Nowa Kaledonia', name_en: 'New Caledonia', value: 'NC' },
    { label: 'Nowa Zelandia', name_en: 'New Zealand', value: 'NZ' },
    { label: 'Oman', name_en: 'Oman', value: 'OM' },
    { label: 'Pakistan', name_en: 'Pakistan', value: 'PK' },
    { label: 'Palau', name_en: 'Palau', value: 'PW' },
    {
        label: 'Palestyna',
        name_en: 'Palestinian Territory, Occupied',
        value: 'PS',
    },
    { label: 'Panama', name_en: 'Panama', value: 'PA' },
    { label: 'Papua-Nowa Gwinea', name_en: 'Papua New Guinea', value: 'PG' },
    { label: 'Paragwaj', name_en: 'Paraguay', value: 'PY' },
    { label: 'Peru', name_en: 'Peru', value: 'PE' },
    { label: 'Pitcairn', name_en: 'Pitcairn', value: 'PN' },
    { label: 'Polinezja Francuska', name_en: 'French Polynesia', value: 'PF' },
    { label: 'Polska', name_en: 'Poland', value: 'PL' },
    { label: 'Portoryko', name_en: 'Puerto Rico', value: 'PR' },
    { label: 'Portugalia', name_en: 'Portugal', value: 'PT' },
    {
        label: 'Republika Południowej Afryki',
        name_en: 'South Africa',
        value: 'ZA',
    },
    {
        label: 'Republika Środkowoafrykańska',
        name_en: 'Central African Republic',
        value: 'CF',
    },
    {
        label: 'Republika Zielonego Przylądka',
        name_en: 'Cape Verde',
        value: 'CV',
    },
    { label: 'Reunion', name_en: 'Réunion', value: 'RE' },
    { label: 'Rosja', name_en: 'Russian Federation', value: 'RU' },
    { label: 'Rumunia', name_en: 'Romania', value: 'RO' },
    { label: 'Rwanda', name_en: 'Rwanda', value: 'RW' },
    { label: 'Sahara Zachodnia', name_en: 'Western Sahara', value: 'EH' },
    {
        label: 'Saint Kitts i Nevis',
        name_en: 'Saint Kitts and Nevis',
        value: 'KN',
    },
    { label: 'Saint Lucia', name_en: 'Saint Lucia', value: 'LC' },
    {
        label: 'Saint Vincent i Grenadyny',
        name_en: 'Saint Vincent and the Grenadines',
        value: 'VC',
    },
    { label: 'Saint-Barthélemy', name_en: 'Saint Barthélemy', value: 'BL' },
    {
        label: 'Saint-Martin',
        name_en: 'Saint Martin (French part)',
        value: 'MF',
    },
    {
        label: 'Saint-Pierre i Miquelon',
        name_en: 'Saint Pierre and Miquelon',
        value: 'PM',
    },
    { label: 'Salwador', name_en: 'El Salvador', value: 'SV' },
    { label: 'Samoa Amerykańskie', name_en: 'American Samoa', value: 'AS' },
    { label: 'Samoa', name_en: 'Samoa', value: 'WS' },
    { label: 'San Marino', name_en: 'San Marino', value: 'SM' },
    { label: 'Senegal', name_en: 'Senegal', value: 'SN' },
    { label: 'Serbia', name_en: 'Serbia', value: 'RS' },
    { label: 'Seszele', name_en: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', name_en: 'Sierra Leone', value: 'SL' },
    { label: 'Singapur', name_en: 'Singapore', value: 'SG' },
    {
        label: 'Sint Maarten',
        name_en: 'Sint Maarten (Dutch part)',
        value: 'SX',
    },
    { label: 'Słowacja', name_en: 'Slovakia', value: 'SK' },
    { label: 'Słowenia', name_en: 'Slovenia', value: 'SI' },
    { label: 'Somalia', name_en: 'Somalia', value: 'SO' },
    { label: 'Sri Lanka', name_en: 'Sri Lanka', value: 'LK' },
    { label: 'Stany Zjednoczone', name_en: 'United States', value: 'US' },
    { label: 'Suazi', name_en: 'Swaziland', value: 'SZ' },
    { label: 'Sudan', name_en: 'Sudan', value: 'SD' },
    { label: 'Sudan Południowy', name_en: 'South Sudan', value: 'SS' },
    { label: 'Surinam', name_en: 'Suriname', value: 'SR' },
    {
        label: 'Svalbard i Jan Mayen',
        name_en: 'Svalbard and Jan Mayen',
        value: 'SJ',
    },
    { label: 'Syria', name_en: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Szwajcaria', name_en: 'Switzerland', value: 'CH' },
    { label: 'Szwecja', name_en: 'Sweden', value: 'SE' },
    { label: 'Tadżykistan', name_en: 'Tajikistan', value: 'TJ' },
    { label: 'Tajlandia', name_en: 'Thailand', value: 'TH' },
    { label: 'Tajwan', name_en: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tanzania', name_en: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Timor Wschodni', name_en: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', name_en: 'Togo', value: 'TG' },
    { label: 'Tokelau', name_en: 'Tokelau', value: 'TK' },
    { label: 'Tonga', name_en: 'Tonga', value: 'TO' },
    { label: 'Trynidad i Tobago', name_en: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunezja', name_en: 'Tunisia', value: 'TN' },
    { label: 'Turcja', name_en: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', name_en: 'Turkmenistan', value: 'TM' },
    {
        label: 'Turks i Caicos',
        name_en: 'Turks and Caicos Islands',
        value: 'TC',
    },
    { label: 'Tuvalu', name_en: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', name_en: 'Uganda', value: 'UG' },
    { label: 'Ukraina', name_en: 'Ukraine', value: 'UA' },
    { label: 'Urugwaj', name_en: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', name_en: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', name_en: 'Vanuatu', value: 'VU' },
    { label: 'Wallis i Futuna', name_en: 'Wallis and Futuna', value: 'WF' },
    { label: 'Watykan', name_en: 'Holy See (Vatican City State)', value: 'VA' },
    {
        label: 'Wenezuela',
        name_en: 'Venezuela, Bolivarian Republic of',
        value: 'VE',
    },
    { label: 'Węgry', name_en: 'Hungary', value: 'HU' },
    { label: 'Wielka Brytania', name_en: 'United Kingdom', value: 'GB' },
    { label: 'Wietnam', name_en: 'Viet Nam', value: 'VN' },
    { label: 'Włochy', name_en: 'Italy', value: 'IT' },
    {
        label: 'Wybrzeże Kości Słoniowej',
        name_en: "Côte d'Ivoire",
        value: 'CI',
    },
    { label: 'Wyspa Bouveta', name_en: 'Bouvet Island', value: 'BV' },
    {
        label: 'Wyspa Bożego Narodzenia',
        name_en: 'Christmas Island',
        value: 'CX',
    },
    { label: 'Wyspa Man', name_en: 'Isle of Man', value: 'IM' },
    {
        label: 'Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha',
        name_en: 'Saint Helena, Ascension and Tristan Cunha',
        value: 'SH',
    },
    { label: 'Wyspy Alandzkie', name_en: 'Åland Islands', value: 'AX' },
    { label: 'Wyspy Cooka', name_en: 'Cook Islands', value: 'CK' },
    {
        label: 'Wyspy Dziewicze Stanów Zjednoczonych',
        name_en: 'Virgin Islands, U.S.',
        value: 'VI',
    },
    {
        label: 'Wyspy Heard i McDonalda',
        name_en: 'Heard Island and McDonald Islands',
        value: 'HM',
    },
    {
        label: 'Wyspy Kokosowe',
        name_en: 'Cocos (Keeling) Islands',
        value: 'CC',
    },
    { label: 'Wyspy Marshalla', name_en: 'Marshall Islands', value: 'MH' },
    { label: 'Wyspy Owcze', name_en: 'Faroe Islands', value: 'FO' },
    { label: 'Wyspy Salomona', name_en: 'Solomon Islands', value: 'SB' },
    {
        label: 'Wyspy Świętego Tomasza i Książęca',
        name_en: 'Sao Tome and Principe',
        value: 'ST',
    },
    { label: 'Zambia', name_en: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', name_en: 'Zimbabwe', value: 'ZW' },
    {
        label: 'Zjednoczone Emiraty Arabskie',
        name_en: 'United Arab Emirates',
        value: 'AE',
    },
]

export const POST_CODES = new Map(
    Object.entries({
        AF: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        AX: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        AL: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        DZ: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        AS: {
            format: 'NNNNN (optionally NNNNN-NNNN or NNNNN-NNNNNN)',
            regex: new RegExp('^\\d{5}(-{1}\\d{4,6})$'),
        },
        AD: {
            format: 'CCNNN',
            regex: new RegExp('^[Aa][Dd]\\d{3}$'),
        },
        AI: {
            format: 'AI-2640',
            regex: new RegExp('^[Aa][I][-][2][6][4][0]$'),
        },
        AR: {
            format: '1974-1998 NNNN; From 1999 ANNNNAAA',
            regex: new RegExp('^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$'),
        },
        AM: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        AC: {
            format: 'AAAANAA one code: ASCN 1ZZ',
            regex: new RegExp('^[Aa][Ss][Cc][Nn]\\s{0,1}[1][Zz][Zz]$'),
        },
        AU: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        AT: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        AZ: {
            format: 'CCNNNN',
            regex: new RegExp('^[Aa][Zz]\\d{4}$'),
        },
        BH: {
            format: 'NNN or NNNN',
            regex: new RegExp('^\\d{3,4}$'),
        },
        BD: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        BB: {
            format: 'CCNNNNN',
            regex: new RegExp('^[Aa][Zz]\\d{5}$'),
        },
        BY: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        BE: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        BM: {
            format: 'AA NN or AA AA',
            regex: new RegExp('^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$'),
        },
        BT: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        BO: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        BA: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        BR: {
            format: 'NNNNN-NNN (NNNNN from 1971 to 1992)',
            regex: new RegExp('^\\d{5}-\\d{3}$'),
        },
        IO: {
            format: 'AAAANAA one code: BBND 1ZZ',
            regex: new RegExp('^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$'),
        },
        VG: {
            format: 'CCNNNN',
            regex: new RegExp('^[Vv][Gg]\\d{4}$'),
        },
        BN: {
            format: 'AANNNN',
            regex: new RegExp('^[A-Za-z]{2}\\d{4}$'),
        },
        BG: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        KH: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        CA: {
            format: 'ANA NAN',
            regex: new RegExp(
                '^(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\s{0,1}\\d(?=[^DdFfIiOoQqUu\\d\\s])[A-Za-z]\\d$'
            ),
        },
        CV: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        KY: {
            format: 'CCN-NNNN',
            regex: new RegExp('^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$'),
        },
        TD: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        CL: {
            format: 'NNNNNNN (NNN-NNNN)',
            regex: new RegExp('^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$'),
        },
        CN: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        CX: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        CC: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        CO: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        CR: {
            format: 'NNNNN (NNNN until 2007)',
            regex: new RegExp('^\\d{4,5}$'),
        },
        HR: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        CU: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        CY: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        CZ: {
            format: 'NNNNN (NNN NN)',
            regex: new RegExp('^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$'),
        },
        DK: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        DO: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        EC: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        SV: {
            format: '1101',
            regex: new RegExp('^1101$'),
        },
        EG: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        EE: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        ET: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        FK: {
            format: 'AAAANAA one code: FIQQ 1ZZ',
            regex: new RegExp('^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$'),
        },
        FO: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        FI: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        FR: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        GF: {
            format: '973NN',
            regex: new RegExp('^973\\d{2}$'),
        },
        PF: {
            format: '987NN',
            regex: new RegExp('^987\\d{2}$'),
        },
        GA: {
            format: 'NN [city name] NN',
            regex: new RegExp('^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$'),
        },
        GE: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        DE: {
            format: 'NN',
            regex: new RegExp('^\\d{2}$'),
        },
        DE: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        DE: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        GI: {
            format: 'GX11 1AA',
            regex: new RegExp('^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$'),
        },
        GR: {
            format: 'NNN NN',
            regex: new RegExp('^\\d{3}\\s{0,1}\\d{2}$'),
        },
        GL: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        GP: {
            format: '971NN',
            regex: new RegExp('^971\\d{2}$'),
        },
        GU: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        GT: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        GG: {
            format: 'AAN NAA, AANN NAA',
            regex: new RegExp('^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$'),
        },
        GW: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        HT: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        HM: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        HN: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        HU: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        IS: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        IN: {
            format: 'NNNNNN,&#10;NNN NNN',
            regex: new RegExp('^\\d{6}$'),
        },
        ID: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        IR: {
            format: 'NNNNN-NNNNN',
            regex: new RegExp('^\\d{5}-\\d{5}$'),
        },
        IQ: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        IM: {
            format: 'CCN NAA, CCNN NAA',
            regex: new RegExp('^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$'),
        },
        IL: {
            format: 'NNNNNNN, NNNNN',
            regex: new RegExp('^\\b\\d{5}(\\d{2})?$'),
        },
        IT: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        JM: {
            format: 'Before suspension: CCAAANN &#10;After suspension: NN',
            regex: new RegExp('^\\d{2}$'),
        },
        JP: {
            format: 'NNNNNNN (NNN-NNNN)',
            regex: new RegExp('^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$'),
        },
        JE: {
            format: 'CCN NAA',
            regex: new RegExp('^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$'),
        },
        JO: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        KZ: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        KE: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        KR: {
            format: 'NNNNNN (NNN-NNN)(1988~2015)',
            regex: new RegExp('^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$'),
        },
        XK: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        KW: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        KG: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        LV: {
            format: 'LV-NNNN',
            regex: new RegExp('^[Ll][Vv][- ]{0,1}\\d{4}$'),
        },
        LA: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        LB: {
            format: 'NNNN NNNN',
            regex: new RegExp('^\\d{4}\\s{0,1}\\d{4}$'),
        },
        LS: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        LR: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        LY: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        LI: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        LT: {
            format: 'LT-NNNNN',
            regex: new RegExp('^[Ll][Tt][- ]{0,1}\\d{5}$'),
        },
        LU: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        MK: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        MG: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        MV: {
            format: 'NNNN, NNNNN',
            regex: new RegExp('^\\d{4,5}$'),
        },
        MY: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        MT: {
            format: 'AAANNNN (AAA NNNN)',
            regex: new RegExp('^[A-Za-z]{3}\\s{0,1}\\d{4}$'),
        },
        MH: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        MQ: {
            format: '972NN',
            regex: new RegExp('^972\\d{2}$'),
        },
        YT: {
            format: '976NN',
            regex: new RegExp('^976\\d{2}$'),
        },
        FM: {
            format: 'NNNNN or NNNNN-NNNN',
            regex: new RegExp('^\\d{5}(-{1}\\d{4})$'),
        },
        MX: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        FM: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        MD: {
            format: 'CCNNNN (CC-NNNN)',
            regex: new RegExp('^[Mm][Dd][- ]{0,1}\\d{4}$'),
        },
        MC: {
            format: '980NN',
            regex: new RegExp('^980\\d{2}$'),
        },
        MN: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        ME: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        MS: {
            format: 'MSR 1110-1350',
            regex: new RegExp('^[Mm][Ss][Rr]\\s{0,1}\\d{4}$'),
        },
        MA: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        MZ: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        MM: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        NA: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        NP: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        NL: {
            format: 'NNNN AA',
            regex: new RegExp('^\\d{4}\\s{0,1}[A-Za-z]{2}$'),
        },
        NC: {
            format: '988NN',
            regex: new RegExp('^988\\d{2}$'),
        },
        NZ: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        NI: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        NE: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        NG: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        NF: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        MP: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        NO: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        OM: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        PK: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        PW: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        PA: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        PG: {
            format: 'NNN',
            regex: new RegExp('^\\d{3}$'),
        },
        PY: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        PE: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        PH: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        PN: {
            format: 'AAAANAA one code: PCRN 1ZZ',
            regex: new RegExp('^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$'),
        },
        PL: {
            format: 'NN-NNN',
            regex: new RegExp('^\\d{2}-{0,1}\\d{3}$'),
        },
        PT: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        PT: {
            format: 'NNNN-NNN (NNNN NNN)',
            regex: new RegExp('^\\d{4}[- ]{0,1}\\d{3}$'),
        },
        PR: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        RE: {
            format: '974NN',
            regex: new RegExp('^974\\d{2}$'),
        },
        RO: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        RU: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        BL: {
            format: '97133',
            regex: new RegExp('^97133$'),
        },
        SH: {
            format: 'STHL 1ZZ',
            regex: new RegExp('^[Ss][Tt][Hh][Ll]\\s{0,1}[1][Zz]{2}$'),
        },
        MF: {
            format: '97150',
            regex: new RegExp('^97150$'),
        },
        PM: {
            format: '97500',
            regex: new RegExp('^97500$'),
        },
        VC: {
            format: 'CCNNNN',
            regex: new RegExp('^[Vv][Cc]\\d{4}$'),
        },
        SM: {
            format: '4789N',
            regex: new RegExp('^4789\\d$'),
        },
        SA: {
            format: 'NNNNN for PO Boxes. NNNNN-NNNN for home delivery.',
            regex: new RegExp('^\\d{5}(-{1}\\d{4})?$'),
        },
        SN: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        RS: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        RS: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        SG: {
            format: 'NN',
            regex: new RegExp('^\\d{2}$'),
        },
        SG: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        SG: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        SK: {
            format: 'NNNNN (NNN NN)',
            regex: new RegExp('^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$'),
        },
        SI: {
            format: 'NNNN (CC-NNNN)',
            regex: new RegExp('^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$'),
        },
        ZA: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        GS: {
            format: 'SIQQ 1ZZ',
            regex: new RegExp('^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$'),
        },
        KR: {
            format: 'NNNNNN (NNN-NNN)',
            regex: new RegExp('^\\d{6}\\s\\(\\d{3}-\\d{3}\\)$'),
        },
        ES: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        LK: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        SD: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        SZ: {
            format: 'ANNN',
            regex: new RegExp('^[A-Za-z]\\d{3}$'),
        },
        SE: {
            format: 'NNNNN (NNN NN)',
            regex: new RegExp('^\\d{3}\\s*\\d{2}$'),
        },
        CH: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        SJ: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        TW: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        TJ: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        TH: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        TT: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        SH: {
            format: 'TDCU 1ZZ',
            regex: new RegExp('^[Tt][Dd][Cc][Uu]\\s{0,1}[1][Zz]{2}$'),
        },
        TN: {
            format: 'NNNN',
            regex: new RegExp('^\\d{4}$'),
        },
        TR: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        TM: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        TC: {
            format: 'TKCA 1ZZ',
            regex: new RegExp('^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$'),
        },
        UA: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        GB: {
            format: 'A(A)N(A/N)NAA (A[A]N[A/N] NAA)',
            regex: new RegExp(
                '^[A-Z]{1,2}[0-9R][0-9A-Z]?\\s*[0-9][A-Z-[CIKMOV]]{2}'
            ),
        },
        US: {
            format: 'NNNNN (optionally NNNNN-NNNN)',
            regex: new RegExp('^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$'),
        },
        UY: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        VI: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
        UZ: {
            format: 'NNN NNN',
            regex: new RegExp('^\\d{3} \\d{3}$'),
        },
        VA: {
            format: '120',
            regex: new RegExp('^120$'),
        },
        VE: {
            format: 'NNNN or NNNN A',
            regex: new RegExp('^\\d{4}(\\s[a-zA-Z]{1})?$'),
        },
        VN: {
            format: 'NNNNNN',
            regex: new RegExp('^\\d{6}$'),
        },
        WF: {
            format: '986NN',
            regex: new RegExp('^986\\d{2}$'),
        },
        ZM: {
            format: 'NNNNN',
            regex: new RegExp('^\\d{5}$'),
        },
    })
)
