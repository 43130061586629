import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { ArrowRightIcon, ArrowUpIcon } from '../../../../icons'
import { Button, Popup } from '../../../../components'
import { useWizard } from '../../../../context'
import { useFormContext } from 'react-hook-form'
import { FORM_FIELDS, COMPLETION_TIME_LABEL } from '../../../../constants'
import { formatDuration } from '../../../../utils/format-duration'
import { INDIVIDUAL_PRICING } from '../../../../utils/calculate-price'
import { IndividualPricingModal } from '../../components/individual-pricing-modal'
import { ErrorMessage } from '../../../error/components'

const SSummary = styled.div`
    background-color: ${({ theme: { colors } }) => colors.light};

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-top: 2px solid ${({ theme: { colors } }) => colors.primary};
        padding: 10px 20px;
    }

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-radius: 20px;
        padding: 30px;
    }
`

const SExpandableSummary = styled.div`
    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        transition: height 0.3s linear;
        overflow: hidden;
        ${({ expanded }) =>
            expanded
                ? css`
                      height: 100px;
                      border-bottom: 2px solid
                          ${({ theme: { colors } }) => colors.gray_1};
                  `
                : css`
                      height: 0px;
                  `};
    }

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-bottom: 2px solid ${({ theme: { colors } }) => colors.gray_1};
    }
`

const SRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.gray_1};
    margin-bottom: 10px;
`

const SExpandableRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        padding-bottom: 16px;
    }
`

const SBoldText = styled.p`
    font-size: 14px;
    font-weight: 700;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 20px;
    }
`

const SText = styled.p`
    font-size: 15px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 16px;
        white-space: nowrap;
    }
`

const SArrowUpIcon = styled(ArrowUpIcon)`
    width: 10px;
    transition: transform 0.3s linear;
    transform: ${({ expanded }) =>
        expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const SExpandButton = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 400px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        display: none;
    }
`

const SPriceRow = styled(SRow)`
    padding-top: 5px;
    border-bottom: none;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        padding-top: 15px;
    }
`

const SButton = styled(Button)`
    width: 100%;
`

const SArrowRightIcon = styled(ArrowRightIcon)`
    width: 19px;
    height: 19px;
`

const SPopup = styled(Popup)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        max-width: 500px;
    }
`

export const Summary = () => {
    const {
        totalDuration,
        price,
        summaryExpanded,
        setSummaryExpanded,
        uploadInProgress,
        analysisInProgress,
        unverifiedFiles,
        submitting,
        submitError,
        clientSessionId,
        setSubmitError,
    } = useWizard()
    const { watch } = useFormContext()
    const [showModal, setShowModal] = useState(false)

    const completionTime = watch(FORM_FIELDS.completionTime)
    const calculatingPrice = uploadInProgress || analysisInProgress

    const hasUnverifiedFiles = useMemo(() => {
        return unverifiedFiles.length > 0
    }, [unverifiedFiles])

    const requiresIndividualPricing =
        !analysisInProgress &&
        (price === INDIVIDUAL_PRICING || hasUnverifiedFiles)

    useEffect(() => {
        if (price === INDIVIDUAL_PRICING) setShowModal(true)
    }, [price])

    useEffect(() => {
        if (hasUnverifiedFiles) setShowModal(true)
    }, [hasUnverifiedFiles])

    const displayPrice = useMemo(() => {
        if (analysisInProgress) return 'Trwa obliczanie...'

        if (requiresIndividualPricing) return 'Wycena indywidualna'

        return price ? `${price.toFixed(2)} zł` : '-'
    }, [analysisInProgress, price, requiresIndividualPricing])

    const displayDuration = totalDuration ? formatDuration(totalDuration) : '-'

    const sendMail = () => {
        window.open('mailto:biuro@protranskrypcje.pl')
    }

    const getBtnContent = () => {
        if (analysisInProgress) return <>Trwa analiza plików...</>

        if (uploadInProgress) return <>Trwa upload plików...</>

        return (
            <>
                Zamawiam i płacę <SArrowRightIcon />
            </>
        )
    }

    return (
        <SSummary>
            <SRow>
                <SBoldText>Podsumowanie</SBoldText>
                <SExpandButton
                    type="button"
                    onClick={() => setSummaryExpanded(!summaryExpanded)}
                >
                    {summaryExpanded ? 'Schowaj' : 'Rozwiń'}{' '}
                    <SArrowUpIcon expanded={summaryExpanded} />
                </SExpandButton>
            </SRow>
            {/* TODO: values from context */}
            <SExpandableSummary expanded={summaryExpanded}>
                <SExpandableRow>
                    <SText>Długość przesłanych nagrań:</SText>
                    <SText>{displayDuration}</SText>
                </SExpandableRow>
                <SExpandableRow>
                    <SText>Wycena:</SText>
                    <SText>{displayPrice}</SText>
                </SExpandableRow>
                <SExpandableRow>
                    <SText>Czas realizacji:</SText>
                    <SText>{COMPLETION_TIME_LABEL[completionTime]}</SText>
                </SExpandableRow>
            </SExpandableSummary>

            <SPriceRow>
                <SBoldText>Razem</SBoldText>
                <SBoldText>{displayPrice}</SBoldText>
            </SPriceRow>
            {requiresIndividualPricing && !calculatingPrice ? (
                <SButton type="button" onClick={sendMail}>
                    Skontaktuj się z nami <SArrowRightIcon />
                </SButton>
            ) : (
                <SButton
                    type="submit"
                    disabled={
                        submitting ||
                        uploadInProgress ||
                        analysisInProgress ||
                        hasUnverifiedFiles
                    }
                >
                    {getBtnContent()}
                </SButton>
            )}
            {showModal && (
                <IndividualPricingModal
                    unverifiedFiles={unverifiedFiles}
                    onClose={() => setShowModal(false)}
                />
            )}
            {submitError && (
                <SPopup
                    fixed
                    withCloseButton
                    onClose={() => setSubmitError(null)}
                >
                    <ErrorMessage
                        text={submitError}
                        orderId={clientSessionId}
                    />
                </SPopup>
            )}
        </SSummary>
    )
}
