import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useWizard } from '../../../../context'

export const FormWrapper = ({ children }) => {
    const { handleSubmit } = useFormContext()
    const { submit } = useWizard()

    return <form onSubmit={handleSubmit(submit)}>{children}</form>
}
