import React from 'react'
import styled, { css } from 'styled-components'
import { AcceptIcon, DollarIcon, EditIcon, LockIcon } from '../../../../icons'

const STags = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        display: none;
    }
`

const STag = styled.li`
    background-color: ${({ theme: { colors } }) => colors.secondary_light};
    color: ${({ theme: { colors } }) => colors.secondary_darker};
    border-radius: 10px;
    font-size: 14px;
    height: 48px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
`

const iconStyle = css`
    height: 24px;
    width: 24px;
`

const SDollarIcon = styled(DollarIcon)`
    ${iconStyle};
`

const SLockIcon = styled(LockIcon)`
    ${iconStyle}
`

const SAcceptIcon = styled(AcceptIcon)`
    ${iconStyle}
`

const SEditIcon = styled(EditIcon)`
    ${iconStyle}
`

export const Tags = () => {
    return (
        <STags>
            <STag>
                <SDollarIcon /> Bezpieczna płatność
            </STag>
            <STag>
                <SLockIcon /> Gwarancja poufności
            </STag>
            <STag>
                <SAcceptIcon /> Wykonaliśmy już ponad 14000 stenogramów dla 350+
                klientów
            </STag>
            <STag>
                <SEditIcon /> 2 poprawki gotowego stenogramu GRATIS
            </STag>
        </STags>
    )
}
