import React, { useEffect } from 'react'
import { SectionWrapper } from '../../components'
import { Checkbox, RadioButton } from '../../../../components'
import { CLIENT_TYPE, FORM_FIELDS } from '../../../../constants'
import { useFormContext } from 'react-hook-form'
import { DifferentInvoiceData } from './different-invoice-data'
import styled from 'styled-components'
import { ReceiverData } from './receiver-data'

const SCheckboxWrapper = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 15px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.lg}) {
        flex-direction: column;
    }
`

const SHeader = styled.p`
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
`

export const InvoiceDataSection = () => {
    const { register, watch, trigger, formState } = useFormContext()
    const [
        useDifferentDataForInvoice,
        includeReceiverDataOnInvoice,
        clientType,
    ] = watch([
        FORM_FIELDS.useDifferentDataForInvoice,
        FORM_FIELDS.includeReceiverDataOnInvoice,
        FORM_FIELDS.clientType,
    ])

    useEffect(() => {
        if (formState.isSubmitted) trigger()
    }, [useDifferentDataForInvoice, includeReceiverDataOnInvoice])

    const showHeaders =
        clientType === CLIENT_TYPE.business &&
        useDifferentDataForInvoice &&
        includeReceiverDataOnInvoice

    return (
        <SectionWrapper title="Dokument sprzedaży">
            <RadioButton
                id="invoice"
                name="invoice"
                label="Elektroniczny"
                checked
                note="Dane do dokumentu sprzedaży są takie same jak dane adresowe."
            />

            <SCheckboxWrapper>
                <Checkbox
                    id="differentDataForInvoiceCheckbox"
                    label="Inne dane do dokumentu sprzedaży"
                    {...register(FORM_FIELDS.useDifferentDataForInvoice)}
                />
                {clientType === CLIENT_TYPE.business && (
                    <Checkbox
                        id="receiverDataCheckbox"
                        label="Dodaj dane Odbiorcy na fakturze"
                        {...register(FORM_FIELDS.includeReceiverDataOnInvoice)}
                    />
                )}
            </SCheckboxWrapper>

            <div>
                {showHeaders && <SHeader>Nabywca:</SHeader>}
                {useDifferentDataForInvoice && <DifferentInvoiceData />}

                {showHeaders && <SHeader>Odbiorca:</SHeader>}
                {includeReceiverDataOnInvoice && <ReceiverData />}
            </div>
        </SectionWrapper>
    )
}
