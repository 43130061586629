import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Error } from '../../views/error'
import { useWizard } from '../../context'
import { STEPS } from '../../constants'

export const ErrorPage = () => {
    let { orderId } = useParams()
    const { setActiveStep, setStepError } = useWizard()

    useEffect(() => {
        setActiveStep(STEPS[2])
        setStepError(STEPS[1])
    }, [])

    return <Error orderId={orderId} />
}
