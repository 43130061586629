import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { DeleteIcon, ErrorIcon, UploadIcon } from '../../icons'
import { formatSize } from '../../utils/format-size.js'
import { formatDuration } from '../../utils/format-duration.js'
import { Error } from '../error/index.js'
import { INDIVIDUAL_PRICING_THRESHOLD } from '../../constants/index.js'
import { getExtension } from '../../utils/get-extension.js'

const SRecording = styled.li`
    background-color: ${({ theme: { colors } }) => colors.light};
    border-radius: 5px;
    min-height: 80px;
    padding: 12px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-bottom: 10px;
`

const SInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    justify-content: space-between;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        gap: 10px;
    }
`

const SDetailsWrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 34px);

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        gap: 16px;
        width: calc(100% - 40px);
    }
`

const SProgressBar = styled.div`
    width: ${({ progress }) => `${progress}%`};
    height: 3px;
    background-color: ${({ theme: { colors } }) => colors.secondary};
    border-radius: 2px;
    transition: width 0.1s linear;
`

const SDetails = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    max-width: calc(100% - 34px);
    overflow: hidden;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        width: calc(100% - 40px);
    }
`

const STitle = styled.p`
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme: { colors } }) => colors.dark};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SText = styled.p`
    display: flex;
    gap: 4px;
    margin-top: 5px;
    flex-wrap: wrap;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        gap: 8px;
    }
`

const SUploadIcon = styled(UploadIcon)`
    flex-shrink: 0;
    flex-grow: 0;
    width: 24px;
    height: 24px;
`

const SErrorIcon = styled(ErrorIcon)`
    flex-shrink: 0;
    flex-grow: 0;
    width: 24px;
    height: 24px;
`

const SDeleteButton = styled.button`
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
`

const SAudio = styled.audio`
    opacity: 0;
`

const SVideo = styled.video`
    opacity: 0;
    height: 0;
    width: 0;
`

const SItem = styled.p`
    font-size: 14px;
    font-weight: 400px;
    color: ${({ theme: { colors } }) => colors.gray_3};
    display: flex;
    gap: 8px;

    &:not(:last-child) {
        &::after {
            content: '•';
            display: block;
            color: ${({ theme: { colors } }) => colors.gray_3};
        }
    }

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 12px;
        gap: 4px;
    }
`

const SError = styled(Error)`
    margin-bottom: 8px;
    width: fit-content;
`

export const Recording = ({
    file,
    progress,
    duration,
    error,
    onDelete,
    onUpdateDuration,
    onFinishProcessing,
}) => {
    const { name, size, type } = file
    const extension = getExtension(file).toUpperCase()

    const items = [
        formatSize(size),
        formatDuration(Math.ceil(duration / 60)),
        extension,
        ...(duration && duration/60 <= INDIVIDUAL_PRICING_THRESHOLD
            ? [`${progress}%`]
            : []),
    ]

    const setDuration = (e) => {
        onUpdateDuration(e.target.duration)
    }

    useEffect(() => {
        setTimeout(() => {
            onFinishProcessing()
        }, 5000)
    }, [])

    const isVideoFile = ['QUICKTIME', 'MOV'].includes(extension)

    const showDeleteButton = useMemo(() => {
        return duration && duration/60 <= INDIVIDUAL_PRICING_THRESHOLD
            ? progress === 100
            : true
    }, [duration, progress])

    return (
        <SRecording onClick={(e) => e.stopPropagation()}>
            <SInnerWrapper>
                <SDetailsWrapper>
                    {error ? <SErrorIcon /> : <SUploadIcon />}
                    <SDetails>
                        <STitle>{name}</STitle>
                        <SText>
                            {items.map((item) => (
                                <SItem key={item}>{item}</SItem>
                            ))}
                        </SText>
                    </SDetails>
                </SDetailsWrapper>
                {showDeleteButton && (
                    <SDeleteButton type="button" onClick={onDelete}>
                        <DeleteIcon />
                    </SDeleteButton>
                )}
            </SInnerWrapper>
            {error ? (
                <SError error={error} />
            ) : (
                <SProgressBar progress={progress} />
            )}
            {isVideoFile ? (
                <SVideo muted preload="metadata" onDurationChange={setDuration}>
                    <source
                        src={window.URL.createObjectURL(file)}
                        type="video/mp4"
                    />
                </SVideo>
            ) : (
                <SAudio muted preload="metadata" onLoadedMetadata={setDuration}>
                    <source
                        src={window.URL.createObjectURL(file)}
                        type={type}
                    />
                </SAudio>
            )}
        </SRecording>
    )
}
