import React from 'react'
import styled from 'styled-components'
import { AcceptIcon, MailIcon } from '../../../../icons'

const SMail = styled.a`
    background-color: ${({ theme: { colors } }) => colors.secondary_opaque};
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: flex-start;
`

const SMailIcon = styled(MailIcon)`
    width: 24px;
    height: 24px;
`

export const Mail = () => {
    return (
        <SMail href="mailto:biuro@protranskrypcje.pl">
            <SMailIcon />
            biuro@protranskrypcje.pl
        </SMail>
    )
}
