import { css } from 'styled-components'

export const inputStyle = css`
    width: 100%;
    border-radius: 5px;
    background-color: ${({ theme: { colors } }) => colors.light};
    border: 2px solid ${({ theme: { colors } }) => colors.gray_1};
    height: 50px;
    padding: 15px;
    transition: border-color 0.3s linear;
    color: ${({ theme: { colors } }) => colors.dark};
    font-weight: 600;

    ${({ theme: { colors }, valid, invalid }) => {
        if (valid)
            return css`
                border-color: ${colors.secondary};
            `
        if (invalid)
            return css`
                border-color: ${colors.error};
            `
    }};

    &:focus {
        border-color: ${({ theme: { colors } }) => colors.primary};
    }

    &::placeholder {
        color: ${({ theme: { colors } }) => colors.dark};
        font-weight: 400;
    }
`
