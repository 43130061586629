const TIME_UNITS = {
    day: 'day',
    hour: 'hour',
    minute: 'minute',
}

const SINGULAR_LABEL = {
    [TIME_UNITS.day]: 'dzień',
    [TIME_UNITS.hour]: 'godzina',
    [TIME_UNITS.minute]: 'minuta',
}

const MULTI_LABEL_1 = {
    [TIME_UNITS.day]: 'dni',
    [TIME_UNITS.hour]: 'godziny',
    [TIME_UNITS.minute]: 'minuty',
}

const MULTI_LABEL_2 = {
    [TIME_UNITS.day]: 'dni',
    [TIME_UNITS.hour]: 'godzin',
    [TIME_UNITS.minute]: 'minut',
}

const getLabel = (value, key) => {
    if (value === 1) return SINGULAR_LABEL[key]

    if (value < 5) return MULTI_LABEL_1[key]

    return MULTI_LABEL_2[key]
}

export const formatDuration = (min) => {
    const time = {
        [TIME_UNITS.day]: Math.floor(min / (60 * 24)),
        [TIME_UNITS.hour]: Math.floor(min / 60) % 24,
        [TIME_UNITS.minute]: min % 60,
    }

    const value = Object.entries(time)
        .filter(([, val]) => val !== 0)
        .map(([key, val]) => `${val} ${getLabel(val, key)}`)
        .join(' ')

    return value ? value : 'N/A'
}
