import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

export const useInputProps = () => {
    const { register, formState, trigger, setValue, getValues } =
        useFormContext()
    const [cursor, setCursor] = useState(null)

    const getInputProps = (name) => {
        return {
            ...register(name),
            error: formState.errors?.[name]?.message,
            validated:
                formState.errors?.[name] ||
                formState.dirtyFields[name] ||
                formState.touchedFields[name] ||
                formState.isSubmitted,
            value: getValues(name),
            onInput: (e) => {
                setCursor(e.target.selectionStart)
                setValue(name, e.target.value)
                trigger(name)
            },
            onBlur: () => {
                trigger(name)
            },
            onFocus: (e) => {
                if (cursor) e.target.selectionStart = cursor
            },
        }
    }

    return getInputProps
}
