import { createBrowserRouter } from 'react-router-dom'

import { IndexPage, OrderPage, ErrorPage } from '../pages'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <IndexPage />,
    },
    {
        path: '/order/:orderId',
        element: <OrderPage />,
    },
    {
        path: '/error/:orderId',
        element: <ErrorPage />,
    },
])
