import React from 'react'
import styled from 'styled-components'

const SWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.light};
    border-radius: 10px;
    padding: 15px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-radius: 20px;
        padding: 30px;
    }
`

const SHeader = styled.p`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 15px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 20px;
        margin-bottom: 20px;
    }
`

export const SectionWrapper = ({ children, title, ...props }) => {
    return (
        <SWrapper {...props}>
            {title && <SHeader>{title}</SHeader>}
            {children}
        </SWrapper>
    )
}
