import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Error } from '../'
import { inputStyle } from '../input/style'
import styled from 'styled-components'
import { useClickOutside } from '../../hooks'
import { usePopper } from 'react-popper'
import { ArrowUpIcon } from '../../icons'

const SInput = styled.input`
    ${inputStyle}
`

const SDropdownWrapper = styled.div`
    position: relative;
`

const SDropdownMenu = styled.ul`
    background-color: ${({ theme: { colors } }) => colors.light};
    border-radius: 5px;
    max-height: 160px;
    overflow-y: auto;
    border: 2px solid ${({ theme: { colors } }) => colors.gray_1};

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: ${({ theme: { colors } }) => colors.gray_2};
    }

    &::-webkit-scrollbar-thumb {
        background: ${({ theme: { colors } }) => colors.primary_darker};
    }

    display: ${({ show }) => (show ? 'block' : 'none')};
    width: ${({ width }) => width}px;
`

const SOption = styled.li`
    padding: 15px;
    transition: background-color 0.3s linear;
    background-color: ${({ theme: { colors }, selected }) =>
        selected ? colors.primary_opaque : colors.light};

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.gray_1_lighter};
    }
`

const SArrowUpIcon = styled(ArrowUpIcon)`
    position: absolute;
    top: 20px;
    right: 15px;
    width: 12px;
    height: 12px;
    transition: transform 0.3s linear;
    transform: ${({ opened }) => (opened ? 'rotate(0deg)' : 'rotate(180deg)')};
`

export const Dropdown = ({
    options,
    validated,
    value,
    error,
    onInput,
    defaultValue,
    ...props
}) => {
    const [showPopper, setShowPopper] = useState(false)
    const [search, setSearch] = useState('')
    const ref = useClickOutside(() => setShowPopper(false))
    const inputRef = useRef()
    const dropdownRef = useRef()

    const { styles, attributes, update } = usePopper(
        inputRef?.current,
        dropdownRef?.current,
        {
            strategy: 'fixed',
        }
    )

    useEffect(() => {
        if (showPopper && update) update()
    }, [showPopper, update])

    const normalize = (str) => {
        if (!str) return ''
        return str.trim().toLowerCase()
    }

    const filteredOptions = useMemo(() => {
        const normalizedSearch = normalize(search)

        if (!normalizedSearch) return options

        return options.filter((option) => {
            return (
                normalize(option.label).match(normalizedSearch) ||
                normalize(option.value).match(normalizedSearch)
            )
        })
    }, [search, options])

    const inputValue = useMemo(() => {
        if (showPopper) return search
        return value?.label
    }, [value, search, showPopper])

    const onSelectOption = (option) => {
        setSearch(option.label)
        onInput({ target: { value: option } })
        setShowPopper(false)
    }

    const handleInput = (e) => {
        if (value) onInput({ target: { value: defaultValue } })
        setSearch(e.target.value)
    }

    return (
        <SDropdownWrapper ref={ref}>
            <SInput
                ref={inputRef}
                value={inputValue}
                valid={!error}
                invalid={validated && error}
                onFocus={() => setShowPopper(true)}
                onInput={handleInput}
                {...props}
            />
            <SArrowUpIcon opened={showPopper} />
            {error && <Error error={error} />}

            <SDropdownMenu
                show={showPopper}
                width={inputRef?.current?.offsetWidth || 0}
                ref={dropdownRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {filteredOptions.map((option) => (
                    <SOption
                        onClick={() => onSelectOption(option)}
                        key={option.value}
                        selected={value === option.value}
                    >
                        {option.label}
                    </SOption>
                ))}
            </SDropdownMenu>
        </SDropdownWrapper>
    )
}
