import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Error } from '../error'

const SCheckboxWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`

const SCheckbox = styled.input`
    width: 18px;
    height: 18px;
    transition: all 0.3s linear;
    border: 2px solid
        ${({ theme: { colors }, error }) =>
            error ? colors.error : colors.gray_1};
    border-radius: 2px;
    flex-shrink: 0;
    margin-top: 1px;

    &:checked {
        background-color: ${({ theme: { colors } }) => colors.secondary_darker};
        border-color: ${({ theme: { colors } }) => colors.secondary_darker};
        background-image: url('assets/checkbox.svg');
        background-position: center;
        background-repeat: no-repeat;
    }
`

export const Checkbox = forwardRef(
    ({ label, id, className, error, ...props }, ref) => {
        return (
            <>
                <SCheckboxWrapper className={className}>
                    <SCheckbox
                        ref={ref}
                        error={!!error}
                        type="checkbox"
                        id={id}
                        {...props}
                    />
                    <label for={id}>{label}</label>
                </SCheckboxWrapper>
                {error && <Error error={error} icon />}
            </>
        )
    }
)
