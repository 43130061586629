import { COMPLETION_TIME, INDIVIDUAL_PRICING_THRESHOLD } from '../constants'

export const EXPRESS_PRICING = {
    slot_1: 249,
    slot_2: 449,
    slot_3: 599,
    slot_4: 849,
    slot_5: 1599,
}

export const STANDARD_PRICING = {
    slot_1: 199,
    slot_2: 349,
    slot_3: 499,
    slot_4: 749,
    slot_5: 1449,
}

const PRICING = {
    [COMPLETION_TIME.express]: EXPRESS_PRICING,
    [COMPLETION_TIME.standard]: STANDARD_PRICING,
}

export const INDIVIDUAL_PRICING = 'individual_pricing'

export const calculatePrice = (minutes, mode) => {
    const pricing = PRICING[mode]

    switch (true) {
        case minutes === 0:
            return 0
        case minutes <= 10:
            return pricing.slot_1
        case minutes <= 20:
            return pricing.slot_2
        case minutes <= 30:
            return pricing.slot_3
        case minutes <= 60:
            return pricing.slot_4
        case minutes <= INDIVIDUAL_PRICING_THRESHOLD:
            return pricing.slot_5
        case minutes > INDIVIDUAL_PRICING_THRESHOLD:
            return INDIVIDUAL_PRICING
    }
}
