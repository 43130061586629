import { css } from 'styled-components'

export const buttonStyle = css`
    height: 39px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    padding: 0 20px;
    font-weight: 600;
    cursor: pointer;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        height: 44px;
        font-size: 16px;
    }
`

const outlineStyle = css`
    border: 2px solid ${({ theme: { colors } }) => colors.gray_2};
    background-color: transparent;
    color: ${({ theme: { colors } }) => colors.dark};
    transition: all 0.3s linear;

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.secondary_darker};
        border-color: ${({ theme: { colors } }) => colors.secondary_darker};
        color: ${({ theme: { colors } }) => colors.light};

        svg {
            fill: ${({ theme: { colors } }) => colors.light};
        }
    }

    &:active,
    &:focus {
        background-color: ${({ theme: { colors } }) => colors.secondary_dark};
        border-color: ${({ theme: { colors } }) => colors.secondary_dark};
        color: ${({ theme: { colors } }) => colors.light};

        svg {
            fill: ${({ theme: { colors } }) => colors.light};
        }
    }
`

const fillStyle = css`
    border: none;
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.light};
    transition: background-color 0.3s linear;

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.primary_darker};
    }

    &:active,
    &:focus {
        background-color: ${({ theme: { colors } }) => colors.primary_dark};
        outline: none;
    }

    &:disabled {
        background-color: ${({ theme: { colors } }) => colors.primary_opaque};
        border-color: ${({ theme: { colors } }) => colors.gray_2};
        cursor: disabled;
    }
`

const warningStyle = css`
    border: none;
    background-color: ${({ theme: { colors } }) => colors.error};
    color: ${({ theme: { colors } }) => colors.light};
    transition: background-color 0.3s linear;

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.error_darker};
    }

    &:active,
    &:focus {
        background-color: ${({ theme: { colors } }) => colors.error_dark};
        outline: none;
    }

    &:disabled {
        border-color: ${({ theme: { colors } }) => colors.gray_2};
        color: ${({ theme: { colors } }) => colors.dark};
    }
`

export const BUTTON_VARIANTS = {
    fill: 'fill',
    outline: 'outline',
    warning: 'warning',
}

export const BUTTON_STYLES = {
    [BUTTON_VARIANTS.fill]: fillStyle,
    [BUTTON_VARIANTS.outline]: outlineStyle,
    [BUTTON_VARIANTS.warning]: warningStyle,
}
