import React from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { Button, Popup } from '../../components'
import { theme } from '../../theme'
import styled from 'styled-components'
import { ArrowRightIcon, Logo } from '../../icons'

const SContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 40px 0;
`

const SText = styled.p`
    text-align: center;
    font-weight: 600;
`

const SArrowRightIcon = styled(ArrowRightIcon)`
    width: 19px;
    height: 19px;
`

const SButton = styled(Button)`
    width: 100%;
`

const SLogo = styled(Logo)`
    height: 28px;
`

const SPopup = styled(Popup)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        max-width: 500px;
    }
`

export const PaymentOverlay = ({ transactionPaymentUrl }) => {
    const goToPayment = () => {
        window.open(transactionPaymentUrl, '_self')
    }

    return (
        <SPopup fixed>
            <SContent>
                {transactionPaymentUrl ? (
                    <>
                        <SLogo />
                        <SText>
                            Zostaniesz przeniesiony na stronę TPay w celu
                            sfinalizowania płatności:
                        </SText>
                        <SButton onClick={goToPayment}>
                            Przejdź do płatności <SArrowRightIcon />
                        </SButton>
                    </>
                ) : (
                    <>
                        <ScaleLoader
                            color={theme.colors.secondary}
                            width={6}
                            margin={3}
                            radius={4}
                            height={60}
                        />
                        <SText>
                            Oczekujemy na potwierdzenie płatności, nie zamykaj
                            przeglądarki.
                        </SText>
                    </>
                )}
            </SContent>
        </SPopup>
    )
}
