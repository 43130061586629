import React from 'react'
import styled from 'styled-components'

const SWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.light};
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-radius: 20px;
        padding: 30px;
    }
`

export const SectionWrapper = ({ children, ...props }) => {
    return <SWrapper {...props}>{children}</SWrapper>
}
