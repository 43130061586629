import React from 'react'
import styled from 'styled-components'
import { RADIO_VARIANT, RADIO_STYLE } from './style'

const SRadioWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.lighter};
    transition: border-color 0.3s linear;
    padding: 0 15px;

    ${({ variant }) => RADIO_STYLE[variant]}
`
const SRadioInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const SInput = styled.input`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: ${({ theme: { colors } }) => colors.gray_1};
    cursor: pointer;
    position: relative;
    flex-shrink: 0;

    &:checked {
        &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: ${({ theme: { colors } }) => colors.secondary};
        }
    }
`

const SLabel = styled.label`
    font-size: 15px;
    font-weight: ${({ checked }) => (checked ? 600 : 400)};
    height: 100%;
    padding: 15px 15px 15px 0px;
    flex-grow: 1;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 16px;
    }
`
const SNote = styled.p`
    background-color: ${({ theme: { colors } }) => colors.gray_1};
    margin-bottom: 15px;
    padding: 12px 15px;
    border-radius: 5px;
    font-size: 14px;
`

export const RadioButton = ({
    id,
    label,
    name,
    checked,
    variant = RADIO_VARIANT.outline,
    className,
    note,
    ...props
}) => {
    return (
        <SRadioWrapper
            checked={checked}
            variant={variant}
            className={className}
        >
            <SRadioInnerWrapper>
                <SInput
                    id={id}
                    name={name}
                    type="radio"
                    checked={checked}
                    {...props}
                />
                <SLabel for={id} checked={checked}>
                    {label}
                </SLabel>
            </SRadioInnerWrapper>
            {note && <SNote>{note}</SNote>}
        </SRadioWrapper>
    )
}
