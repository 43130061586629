import { css } from 'styled-components'

export const RADIO_VARIANT = {
    underline: 'underline',
    outline: 'outline',
}

export const RADIO_STYLE = {
    [RADIO_VARIANT.underline]: css`
        border-bottom: 2px solid
            ${({ theme: { colors }, checked }) =>
                checked ? colors.secondary : colors.gray_1};
    `,
    [RADIO_VARIANT.outline]: css`
        border-radius: 5px;
        border: 2px solid
            ${({ theme: { colors }, checked }) =>
                checked ? colors.secondary : colors.gray_1};
    `,
}
