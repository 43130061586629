import { object, string, array, bool } from 'yup'
import { COUNTRIES_OPTIONS, DEFAULT_COUNTRY, POST_CODES } from './countries'

export const ACCEPTED_FORMATS = [
    'avi',
    'mp3',
    'mp4',
    'wav',
    'flac',
    'wmv',
    '3gp',
]

export const EXTENDED_FORMATS = [
    ...ACCEPTED_FORMATS,
    'mpeg',
    '3gpp',
    'x-ms-wmv',
    'quicktime',
    'mpg',
    'mov'
]

export const MAX_SIZE = 2147483648

const PHONE_REGEX =
    /^\+{0,1}\d{0,2}\s?\d{3}(\-|\s){0,1}\d{3}(\-|\s){0,1}\d{3}$/im

const NIP_REGEX = /^[0-9]{10}$/

const GENERAL_POST_CODE_REGEX = /^\d+$/

const NAME_REGEX = /^[a-z ,.'-]+$/i

export const STEPS = [
    'Przesyłanie pliku i dane klienta',
    'Płatność',
    'Podsumowanie',
]

export const COMPLETION_TIME = {
    standard: 'standard',
    express: 'express',
}

export const COMPLETION_TIME_LABEL = {
    [COMPLETION_TIME.standard]: 'Standard - 10 dni roboczych',
    [COMPLETION_TIME.express]: 'EXPRESS - 48H',
}

export const INDIVIDUAL_PRICING_THRESHOLD = 120

export const ERROR_MAP = {
    'Unsupported File Type':
        'Wgrywanie nie powiodło się: nieprawidłowy typ pliku',
    'Security Issue': 'Wgrywanie nie powiodło się: wykryto zagrożenie',
    Timeout: 'Wgrywanie nie powiodło się: czas wgrywania przekroczony',
    'File Too Large':
        'Wgrywanie nie powiodło się: plik przekracza dozwolony rozmiar',
    'Corrupted File': 'Wgrywanie nie powiodło się: plik uszkodzony',
    'Server Errors': 'Wgrywanie nie powiodło się: błąd serwera',
    'I/O Error': 'Wgrywanie nie powiodło się: błąd serwera',
    'Unexpected Error': 'Wgrywanie nie powiodło się: nieoczekiwany błąd',
    'Network Disconnection':
        'Wgrywanie nie powiodło się: połączenie zostało przerwane',
}

export const FORM_FIELDS = {
    files: 'files',
    completionTime: 'completionTime',
    clientType: 'clientType',
    email: 'email',
    name: 'name',
    lastName: 'lastName',
    companyName: 'companyName',
    street: 'street',
    houseNumber: 'houseNumber',
    flatNumber: 'flatNumber',
    postCode: 'postCode',
    city: 'city',
    phoneNumber: 'phoneNumber',
    // business only
    country: 'country',
    nip: 'nip',
    includeReceiverDataOnInvoice: 'includeReceiverDataOnInvoice',
    useDifferentDataForInvoice: 'useDifferentDataForInvoice',
    receiverStreet: 'receiver_street',
    receiverHouseNumber: 'receiver_houseNumber',
    receiverFlatNumber: 'receiver_flatNumber',
    receiverPostCode: 'receiver_postCode',
    receiverCity: 'receiver_city',
    receiverNip: 'receiver_nip',
    receiverCountry: 'receiver_country',
    receiverCompanyName: 'receiver_companyName',
    buyerName: 'buyer_name',
    buyerLastName: 'buyer_lastName',
    buyerCompanyName: 'buyer_companyName',
    buyerStreet: 'buyer_street',
    buyerHouseNumber: 'buyer_houseNumber',
    buyerFlatNumber: 'buyer_flatNumber',
    buyerPostCode: 'buyer_postCode',
    buyerCity: 'buyer_city',
    buyerNip: 'buyer_nip',
    buyerCountry: 'buyer_country',
    includeReceiverNip: 'includeReceiverNip',
    marketingPermission: 'marketingPermission',
    termsAndConditions: 'termsAndConditions',
    personalisedService: 'personalisedService',
    confidentialityStatement: 'confidentialityStatement',
}

export const CLIENT_TYPE = {
    individual: 'individual',
    business: 'business',
}

export const CLIENT_LABEL = {
    [CLIENT_TYPE.individual]: 'Zamawiam jako osoba prywatna',
    [CLIENT_TYPE.business]: 'Zamawiam jako firma lub jednostka budżetowa',
}

export const DEFAULT_VALUES = {
    [FORM_FIELDS.files]: [],
    [FORM_FIELDS.completionTime]: COMPLETION_TIME.express,
    [FORM_FIELDS.clientType]: CLIENT_TYPE.individual,
    [FORM_FIELDS.email]: '',
    [FORM_FIELDS.companyName]: '',
    [FORM_FIELDS.name]: '',
    [FORM_FIELDS.lastName]: '',
    [FORM_FIELDS.street]: '',
    [FORM_FIELDS.houseNumber]: '',
    [FORM_FIELDS.flatNumber]: '',
    [FORM_FIELDS.postCode]: '',
    [FORM_FIELDS.city]: '',
    [FORM_FIELDS.phoneNumber]: '',
    [FORM_FIELDS.nip]: '',
    [FORM_FIELDS.country]: DEFAULT_COUNTRY,
    [FORM_FIELDS.receiverCountry]: DEFAULT_COUNTRY,
    [FORM_FIELDS.buyerCountry]: DEFAULT_COUNTRY,
    [FORM_FIELDS.receiverStreet]: '',
    [FORM_FIELDS.receiverHouseNumber]: '',
    [FORM_FIELDS.receiverFlatNumber]: '',
    [FORM_FIELDS.receiverPostCode]: '',
    [FORM_FIELDS.receiverCity]: '',
    [FORM_FIELDS.receiverNip]: '',
    [FORM_FIELDS.receiverCompanyName]: '',
    [FORM_FIELDS.companyName]: '',
    [FORM_FIELDS.buyerStreet]: '',
    [FORM_FIELDS.buyerHouseNumber]: '',
    [FORM_FIELDS.buyerFlatNumber]: '',
    [FORM_FIELDS.buyerPostCode]: '',
    [FORM_FIELDS.buyerCity]: '',
    [FORM_FIELDS.buyerNip]: '',
    [FORM_FIELDS.buyerName]: '',
    [FORM_FIELDS.buyerCompanyName]: '',
    [FORM_FIELDS.buyerLastName]: '',
    [FORM_FIELDS.marketingPermission]: false,
    [FORM_FIELDS.termsAndConditions]: false,
    [FORM_FIELDS.personalisedService]: false,
    [FORM_FIELDS.confidentialityStatement]: false,

    // FE only
    [FORM_FIELDS.includeReceiverDataOnInvoice]: false,
    [FORM_FIELDS.useDifferentDataForInvoice]: false,
    [FORM_FIELDS.includeReceiverNip]: false,
}

const conditionallyRequired = (baseField, validator) => (value, context) => {
    if (context.parent[baseField]) return validator ? validator(value) : !!value
    return true
}

const nameValidator = (value = '') => {
    return NAME_REGEX.test(value.trim()) && value.trim().length > 1
}

const receiverFormSchema = {
    [FORM_FIELDS.receiverCompanyName]: string().test(
        'receiver_companyName',
        'Podaj nazwę firmy',
        conditionallyRequired(
            FORM_FIELDS.includeReceiverDataOnInvoice,
            (value) => value.trim().length > 2
        )
    ),
    [FORM_FIELDS.receiverStreet]: string().test(
        'receiver',
        'Podaj ulicę',
        conditionallyRequired(FORM_FIELDS.includeReceiverDataOnInvoice)
    ),
    [FORM_FIELDS.receiverHouseNumber]: string().test(
        'receiver',
        'Podaj numer domu',
        conditionallyRequired(FORM_FIELDS.includeReceiverDataOnInvoice)
    ),
    [FORM_FIELDS.receiverFlatNumber]: string(),
    [FORM_FIELDS.receiverPostCode]: string().test(
        'post-code',
        'Podaj poprawny kod pocztowy',
        (value, context) => {
            if (!context.parent.includeReceiverDataOnInvoice) return true

            const { regex } = POST_CODES.get(context.parent.country.value) || {
                regex: GENERAL_POST_CODE_REGEX,
            }

            return regex.test(value)
        }
    ),
    [FORM_FIELDS.receiverCity]: string().test(
        'receiver',
        'Podaj miejscowość',
        conditionallyRequired(FORM_FIELDS.includeReceiverDataOnInvoice)
    ),
    [FORM_FIELDS.receiverNip]: string().test(
        'nip',
        'Podaj poprawny NIP',
        (value, context) => {
            if (
                context.parent.clientType === CLIENT_TYPE.individual ||
                !context.parent[FORM_FIELDS.includeReceiverDataOnInvoice] ||
                !context.parent[FORM_FIELDS.includeReceiverNip]
            )
                return true

            const nipWithoutDashes = value.replace(/-/g, '')

            return NIP_REGEX.test(nipWithoutDashes)
        }
    ),
    [FORM_FIELDS.receiverCountry]: object(
        {
            label: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.label)
            ),
            value: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.value)
            ),
        },
        'Wybierz kraj z listy'
    ).test('receiver country', 'Podaj kraj', (value, context) => {
        if (
            context.parent.clientType === CLIENT_TYPE.individual ||
            !context.parent[FORM_FIELDS.includeReceiverDataOnInvoice]
        )
            return true

        return !!value
    }),
}

const buyerFormSchema = {
    [FORM_FIELDS.buyerName]: string().test(
        'buyer_name',
        'Podaj imię',
        (value, context) => {
            if (
                context.parent[FORM_FIELDS.clientType] ===
                CLIENT_TYPE.individual
            )
                return conditionallyRequired(
                    FORM_FIELDS.useDifferentDataForInvoice,
                    nameValidator
                )(value, context)

            return true
        }
    ),
    [FORM_FIELDS.buyerLastName]: string().test(
        'buyer',
        'Podaj nazwisko',
        (value, context) => {
            if (
                context.parent[FORM_FIELDS.clientType] ===
                CLIENT_TYPE.individual
            )
                return conditionallyRequired(
                    FORM_FIELDS.useDifferentDataForInvoice,
                    nameValidator
                )(value, context)

            return true
        }
    ),
    [FORM_FIELDS.buyerCompanyName]: string().test(
        'buyer',
        'Podaj poprawną nazwę firmy',
        (value, context) => {
            if (context.parent[FORM_FIELDS.clientType] === CLIENT_TYPE.business)
                return conditionallyRequired(
                    FORM_FIELDS.useDifferentDataForInvoice,
                    (value) => value.trim().length > 2
                )(value, context)

            return true
        }
    ),
    [FORM_FIELDS.buyerStreet]: string().test(
        'buyer',
        'Podaj ulicę',
        conditionallyRequired(FORM_FIELDS.useDifferentDataForInvoice)
    ),
    [FORM_FIELDS.buyerHouseNumber]: string().test(
        'buyer',
        'Podaj numer domu',
        conditionallyRequired(FORM_FIELDS.useDifferentDataForInvoice)
    ),
    [FORM_FIELDS.buyerFlatNumber]: string(),
    [FORM_FIELDS.buyerPostCode]: string().test(
        'post-code',
        'Podaj poprawny kod pocztowy',
        (value, context) => {
            if (!context.parent.useDifferentDataForInvoice) return true

            const { regex } = POST_CODES.get(context.parent.country.value) || {
                regex: GENERAL_POST_CODE_REGEX,
            }

            return regex.test(value)
        }
    ),
    [FORM_FIELDS.buyerCity]: string().test(
        'buyer',
        'Podaj miejscowość',
        conditionallyRequired(FORM_FIELDS.useDifferentDataForInvoice)
    ),
    [FORM_FIELDS.buyerNip]: string().test(
        'nip',
        'Podaj poprawny NIP',
        (value, context) => {
            if (
                context.parent.clientType === CLIENT_TYPE.individual ||
                !context.parent[FORM_FIELDS.useDifferentDataForInvoice]
            )
                return true

            const nipWithoutDashes = value.replace(/-/g, '')

            return NIP_REGEX.test(nipWithoutDashes)
        }
    ),
    [FORM_FIELDS.buyerCountry]: object(
        {
            label: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.label)
            ),
            value: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.value)
            ),
        },
        'Wybierz kraj z listy'
    ).test('buyer country', 'Podaj kraj', (value, context) => {
        if (
            context.parent.clientType === CLIENT_TYPE.individual ||
            !context.parent[FORM_FIELDS.useDifferentDataForInvoice]
        )
            return true

        return !!value
    }),
}

export const formSchema = object({
    [FORM_FIELDS.files]: array()
        .required()
        // TODO: handle case for upload in progress
        // .test('uploaded', 'Upload in progress', value => value.find(item => item.progress < 100))
        .test('upload-error', 'Error during upload', (value) => {
            return value.every((item) => !item.error)
        }).test('length', 'Select at least one file', value => value.length > 0),
    [FORM_FIELDS.completionTime]: string()
        .required()
        .oneOf(Object.values(COMPLETION_TIME)),
    [FORM_FIELDS.clientType]: string()
        .required()
        .oneOf(Object.values(CLIENT_TYPE)),
    [FORM_FIELDS.email]: string()
        .email('Podaj poprawny adres email')
        .required('Podaj poprawny adres email'),

    [FORM_FIELDS.phoneNumber]: string().test(
        'phone-number',
        'Podaj poprawny numer telefonu',
        (value) => {
            if (!value) return true
            return PHONE_REGEX.test(value) && value.length >= 9
        }
    ),
    [FORM_FIELDS.name]: string().test(
        'name',
        'Podaj imię',
        (value, context) => {
            if (context.parent.clientType === CLIENT_TYPE.business) return true

            return nameValidator(value)
        }
    ),
    [FORM_FIELDS.lastName]: string().test(
        'name',
        'Podaj nazwisko',
        (value, context) => {
            if (context.parent.clientType === CLIENT_TYPE.business) return true

            return nameValidator(value)
        }
    ),
    [FORM_FIELDS.companyName]: string().test(
        'companyName',
        'Podaj nazwę firmy',
        (value, context) => {
            if (context.parent.clientType === CLIENT_TYPE.individual)
                return true

            return value.trim().length > 2
        }
    ),
    [FORM_FIELDS.street]: string().required('Podaj ulicę'),
    [FORM_FIELDS.houseNumber]: string().required('Podaj numer domu'),
    [FORM_FIELDS.flatNumber]: string(),
    [FORM_FIELDS.postCode]: string()
        .required('Podaj poprawny kod pocztowy')
        .test('post-code', 'Podaj poprawny kod pocztowy', (value, context) => {
            const { regex } = POST_CODES.get(context.parent.country.value) || {
                regex: GENERAL_POST_CODE_REGEX,
            }

            return regex.test(value)
        }),
    [FORM_FIELDS.city]: string().required('Podaj miejscowość'),
    [FORM_FIELDS.nip]: string().test(
        'nip',
        'Podaj poprawny NIP',
        (value, context) => {
            if (context.parent.clientType === CLIENT_TYPE.individual)
                return true

            const nipWithoutDashes = value.replace(/-/g, '')

            return NIP_REGEX.test(nipWithoutDashes)
        }
    ),
    [FORM_FIELDS.country]: object(
        {
            label: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.label)
            ),
            value: string().oneOf(
                COUNTRIES_OPTIONS.map((option) => option.value)
            ),
        },
        'Wybierz kraj z listy'
    ),
    [FORM_FIELDS.includeReceiverDataOnInvoice]: bool(),
    [FORM_FIELDS.useDifferentDataForInvoice]: bool(),
    ...receiverFormSchema,
    ...buyerFormSchema,
    [FORM_FIELDS.marketingPermission]: bool(),
    [FORM_FIELDS.termsAndConditions]: bool().oneOf(
        [true],
        'Ta zgoda jest wymagana'
    ),
    [FORM_FIELDS.personalisedService]: bool().oneOf(
        [true],
        'Ta zgoda jest wymagana'
    ),
    [FORM_FIELDS.confidentialityStatement]: bool(),
})
