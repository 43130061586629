import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Error } from '../../../../components'
import copy from 'copy-to-clipboard'

const SErrorMessage = styled.div`
    text-align: center;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`

const SLink = styled.a`
    color: ${({ theme: { colors } }) => colors.secondary};
    font-weight: 600;
`

const STransactionId = styled.p`
    color: ${({ theme: { colors } }) => colors.secondary};
    font-weight: 600;
`

const SError = styled(Error)`
    align-self: center;
`

export const ErrorMessage = ({
    orderId,
    text = 'Wystąpił błąd przy przetwarzaniu transakcji.',
}) => {
    const [copied, setCopied] = useState(false)

    const copyOrderId = () => {
        copy(orderId)
        setCopied(true)
    }

    return (
        <SErrorMessage>
            <SError
                error={text || 'Wystąpił błąd przy przetwarzaniu transakcji.'}
                icon
            />
            <p>
                Skontaktuj się z biurem obsługi klienta:{' '}
                <SLink href="mailto:biuro@protranskrypcje.pl">
                    biuro@protranskrypcje.pl
                </SLink>
                .
            </p>
            {orderId && (
                <>
                    <p>
                        Aby przyspieszyć proces, przy zgłoszeniu podaj numer
                        transakcji:
                    </p>
                    <STransactionId>
                        #{orderId} {copied && <p>(Numer skopiowany!)</p>}
                    </STransactionId>
                    {
                        <Button onClick={copyOrderId}>
                            Skopiuj numer transakcji
                        </Button>
                    }
                </>
            )}
        </SErrorMessage>
    )
}
