import axios from 'axios'

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const ENDPOINTS = {
    CREATE_SESSION: '/create-sample-client-session',
    DELETE_FILE: '/delete-file',
    UPLOAD: '/upload-file',
    SUBMIT_FORM: '/upload-form-data',
    CLIENT_ORDER_DETAILS: (id) => `/client-order/${id}`,
}
