import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Stepper } from '../components'
import { STEPS } from '../constants'
import { useWizard } from '../context'
import { CallIcon, Logo } from '../icons'
import { Footer } from './components'

const SInnerWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.gray_1};
`

const SLogoWrapper = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        height: 76px;
    }
`

const SContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px ${({ expanded }) => (expanded ? '320px' : '220px')} 20px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        gap: 30px;
        padding: 40px ${({ sidebar }) => (sidebar ? '500px' : '40px')} 40px 40px;
    }
`

const SSidebar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        width: 440px;
        right: 40px;
        bottom: unset;
        left: unset;
        top: ${({ offset }) => offset}px;
    }
`

const SLogo = styled(Logo)`
    height: 22px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        height: 28px;
    }
`

export const Layout = ({ children, sidebar }) => {
    const { activeStep, summaryExpanded, stepError } = useWizard()
    const [sidebarOffset, setSidebarOffset] = useState(166)

    const handleScroll = () => {
        setSidebarOffset(Math.max(166 - window.scrollY, 40))
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div>
            <SLogoWrapper>
                <SLogo />
            </SLogoWrapper>
            <div>
                <Stepper
                    steps={STEPS}
                    activeStep={activeStep}
                    stepError={stepError}
                />
            </div>
            <SInnerWrapper>
                <SContentWrapper expanded={summaryExpanded} sidebar={!!sidebar}>
                    {children}
                </SContentWrapper>
                {sidebar && (
                    <SSidebar offset={sidebarOffset}>{sidebar}</SSidebar>
                )}
            </SInnerWrapper>
            <Footer />
        </div>
    )
}
