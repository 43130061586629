import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Order } from '../../views/order'
import { useWizard } from '../../context'
import { STEPS } from '../../constants'

export const OrderPage = () => {
    let { orderId } = useParams()
    const { setActiveStep } = useWizard()

    useEffect(() => {
        setActiveStep(STEPS[2])
    }, [])

    return <Order orderId={orderId} />
}
