import React from 'react'
import styled from 'styled-components'
import { Button, Popup } from '../../../../components'
import { BUTTON_VARIANTS } from '../../../../components/button/style'

const SText = styled.p`
    font-size: 15px;
    font-weight: 600;
    text-align: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 16px;
    }
`

const SButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-direction: row;
        margin-top: 20px;
    }
`

const SButton = styled(Button)`
    width: 100%;
`

export const DeleteFileModal = ({ onDelete, onClose }) => {
    return (
        <Popup>
            <SText>
                Czy na pewno chcesz usunąć ten plik? Tej operacji nie można
                cofnąć.
            </SText>
            <SButtonWrapper>
                <SButton variant={BUTTON_VARIANTS.outline} onClick={onClose}>
                    Anuluj
                </SButton>
                <SButton variant={BUTTON_VARIANTS.warning} onClick={onDelete}>
                    Usuń
                </SButton>
            </SButtonWrapper>
        </Popup>
    )
}
