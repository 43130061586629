import React from 'react'
import styled, { css } from 'styled-components'

const SWrapper = styled.ul`
    display: block;
    width: 100%;
    background-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.light};
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        height: 50px;
    }
`

const SStep = styled.li`
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.light};
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
        &::after {
            content: '';
            margin-top: 4px;
            display: block;
            width: 20px;
            height: 2px;
            background-color: ${({ theme: { colors } }) => colors.light};
        }
    }

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 14px;

        &:not(:last-child) {
            &::after {
                width: 60px;
            }
        }
    }
`

const getStyle = ({ isCompleted, error, processFailed, theme }) => {
    if (error)
        return css`
            background-color: ${theme.colors.error};
            color: ${theme.colors.light};
        `

    if (processFailed)
        return `
        background-color: ${theme.colors.gray_1};
        color: ${theme.colors.dark};
    `

    if (isCompleted)
        return css`
            background-color: ${({ theme: { colors } }) => colors.secondary};
            color: ${({ theme: { colors } }) => colors.light};
        `

    return css`
        background-color: ${({ theme: { colors } }) => colors.light};
        color: ${({ theme: { colors } }) => colors.dark};
    `
}

const SStepIndex = styled.div`
    width: 24px;
    height: 24px;
    font-weight: 800;
    font-size: 14px;
    border-radius: 12px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    ${getStyle};
`

const SStepTitle = styled.p`
color: inherit;
     @media screen and (max-width: ${({ theme: { breakpoints } }) =>
         breakpoints.md}) {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`

export const Stepper = ({ steps, activeStep, stepError }) => {
    const activeStepIndex = steps.indexOf(activeStep)
    const processFailed = !!stepError

    return (
        <SWrapper>
            {steps.map((step, index) => (
                <SStep key={step}>
                    <SStepIndex
                        isCompleted={index <= activeStepIndex}
                        error={step === stepError}
                        processFailed={processFailed}
                    >
                        {step === stepError ? <>&#x2715;</> : index + 1}
                    </SStepIndex>
                    <SStepTitle isActive={step === activeStep}>
                        {step}
                    </SStepTitle>
                </SStep>
            ))}
        </SWrapper>
    )
}
