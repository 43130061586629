import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './App.css'
import { router } from './router'
import { theme } from './theme'
import { WizardContextProvider } from './context'
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga4'

if (process.env.REACT_APP_GA) ReactGA.initialize(process.env.REACT_APP_GA)

if (process.env.REACT_APP_HOTJAR_ID)
    hotjar.initialize({
        id: process.env.REACT_APP_HOTJAR_ID,
        sv: process.env.REACT_APP_HOTJAR_SV,
    })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <WizardContextProvider>
                <RouterProvider router={router} />
            </WizardContextProvider>
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
