import { CLIENT_TYPE, FORM_FIELDS } from '../constants'

const COMMON_FIELDS = [
    FORM_FIELDS.city,
    FORM_FIELDS.postCode,
    FORM_FIELDS.street,
    FORM_FIELDS.houseNumber,
    FORM_FIELDS.flatNumber,
    FORM_FIELDS.phoneNumber,
    FORM_FIELDS.email,
    FORM_FIELDS.clientType,
    FORM_FIELDS.completionTime,
    FORM_FIELDS.termsAndConditions,
    FORM_FIELDS.marketingPermission,
    FORM_FIELDS.confidentialityStatement,
    FORM_FIELDS.personalisedService,
]
const BUSINESS_GENERAL_FIELDS = [
    FORM_FIELDS.nip,
    FORM_FIELDS.country,
    FORM_FIELDS.companyName,
]
const INDIVIDUAL_GENERAL_FIELDS = [FORM_FIELDS.name, FORM_FIELDS.lastName]

const RECEIVER_FIELDS = [
    FORM_FIELDS.receiverCity,
    FORM_FIELDS.receiverFlatNumber,
    FORM_FIELDS.receiverHouseNumber,
    FORM_FIELDS.receiverCompanyName,
    FORM_FIELDS.receiverPostCode,
    FORM_FIELDS.receiverStreet,
    FORM_FIELDS.receiverNip,
    FORM_FIELDS.receiverCountry,
]

const BUYER_FIELDS = [
    FORM_FIELDS.buyerCity,
    FORM_FIELDS.buyerFlatNumber,
    FORM_FIELDS.buyerHouseNumber,
    FORM_FIELDS.buyerPostCode,
    FORM_FIELDS.buyerStreet,
]
const BUSINESS_BUYER_FIELDS = [
    FORM_FIELDS.buyerNip,
    FORM_FIELDS.buyerCountry,
    FORM_FIELDS.buyerCompanyName,
]

const INDIVIDUAL_BUYER_FIELDS = [
    FORM_FIELDS.buyerLastName,
    FORM_FIELDS.buyerName,
]

export const formatSubmitData = (values, clientId, price) => {
    const formData = {
        fileIds: values[FORM_FIELDS.files].map((file) => file.fileId),
        clientId,
        price,
    }
    const isBusinessClient =
        values[FORM_FIELDS.clientType] === CLIENT_TYPE.business
    const includeReceiverData = values[FORM_FIELDS.includeReceiverDataOnInvoice]
    const useDifferentDataForInvoice =
        values[FORM_FIELDS.useDifferentDataForInvoice]

    const includedFields = [
        ...COMMON_FIELDS,
        ...(isBusinessClient
            ? BUSINESS_GENERAL_FIELDS
            : INDIVIDUAL_GENERAL_FIELDS),
        ...(includeReceiverData && isBusinessClient ? RECEIVER_FIELDS : []),
        ...(useDifferentDataForInvoice ? BUYER_FIELDS : []),
        ...(useDifferentDataForInvoice && isBusinessClient
            ? BUSINESS_BUYER_FIELDS
            : INDIVIDUAL_BUYER_FIELDS),
    ]

    includedFields.forEach((field) => {
        const value = values[field]
        const getNormalizedValue = (value, field) => {
            if (field.toLowerCase().includes('country')) return value.value

            return typeof value === 'string' ? value.trim() : value
        }

        if (![undefined, null, ''].includes(value))
            formData[field] = getNormalizedValue(value, field)
    })

    return formData
}
