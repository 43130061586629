import { useRef, useEffect } from 'react'

export const useClickOutside = (callback) => {
    const ref = useRef()

    function handleEvent(e) {
        /* istanbul ignore else  */
        if (ref && ref.current) {
            if (!ref.current.contains(e.target)) {
                callback()
            }
        }
    }

    useEffect(() => {
        if (window.PointerEvent) {
            document.addEventListener('pointerdown', handleEvent)
        } else {
            document.addEventListener('mousedown', handleEvent)
            document.addEventListener('touchstart', handleEvent)
        }

        return () => {
            if (window.PointerEvent) {
                document.removeEventListener('pointerdown', handleEvent)
            } else {
                document.removeEventListener('mousedown', handleEvent)
                document.removeEventListener('touchstart', handleEvent)
            }
        }
    }, [])

    return ref
}
