import React from 'react'
import { Layout } from '../../layout'
import { ErrorMessage } from './components'
import styled from 'styled-components'

const SWrapper = styled.div`
    min-height: calc(100vh - 380px);
    display: flex;
    align-items; center;
    justify-content: center;

     @media screen and (max-width: ${({ theme: { breakpoints } }) =>
         breakpoints.md}) {
         min-height: calc(100vh - 500px);
    }
`

export const Error = ({ orderId }) => {
    return (
        <Layout>
            <SWrapper>
                <ErrorMessage orderId={orderId} />
            </SWrapper>
        </Layout>
    )
}
