import React from 'react'
import { Layout } from '../../layout'
import { OrderContainer } from './container'
import styled from 'styled-components'

const SContentWrapper = styled.div`
    min-height: calc(100vh - 500px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        min-height: calc(100vh - 360px);
    }
`

export const Order = ({ orderId }) => {
    return (
        <Layout>
            <SContentWrapper>
                <OrderContainer orderId={orderId} />
            </SContentWrapper>
        </Layout>
    )
}
