import React from 'react'
import { ErrorIcon } from '../../icons'
import styled from 'styled-components'

const SErrorWrapper = styled.div`
    display: inline-block;
    margin-top: 8px;
`

const SError = styled.div`
    background-color: ${({ theme: { colors } }) => colors.error_lighter};
    color: ${({ theme: { colors } }) => colors.error};
    padding: 6px 15px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    white-space: pre-wrap;
`

const SErrorIcon = styled(ErrorIcon)`
    width: 20px;
    height: 20px;
`

export const Error = ({ error, icon, className }) => {
    return (
        <SErrorWrapper className={className}>
            <SError>
                {icon && <SErrorIcon />}
                {error}
            </SError>
        </SErrorWrapper>
    )
}
