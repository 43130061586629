import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Error } from '../'
import { inputStyle } from './style'

const SInputWrapper = styled.div`
    width: 100%;
`

const SInput = styled.input`
    ${inputStyle}
`

const SHint = styled.p`
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.gray_3};
    margin-top: 8px;
`

export const Input = forwardRef(
    ({ error, validated, hint, className, ...props }, ref) => {
        return (
            <SInputWrapper className={className}>
                <SInput
                    ref={ref}
                    valid={validated && !error}
                    invalid={validated && error}
                    {...props}
                />
                {hint && <SHint>{hint}</SHint>}
                {error && <Error error={error} icon />}
            </SInputWrapper>
        )
    }
)
