import React from 'react'
import { Popup } from '../../../../components'
import styled from 'styled-components'

const SPopup = styled(Popup)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        max-width: 500px;
    }
`

const SText = styled.p`
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
    padding: 20px 0;
`
const SBold = styled.span`
    font-weight: 800;
`

const SLink = styled.a`
    color: ${({ theme: { colors } }) => colors.primary};
`

export const IndividualPricingModal = ({ unverifiedFiles, onClose }) => {
    return (
        <SPopup fixed withCloseButton onClose={onClose}>
            {unverifiedFiles.length > 0 ? (
                <SText>
                    Nie możemy zweryfikować długości Twojego nagrania:{' '}
                    <SBold>
                        {unverifiedFiles
                            .map((file) => file.file.name)
                            .join(', ')}
                    </SBold>
                    . Skontaktuj się z nami mailowo{' '}
                    <SLink href="mailto:biuro@protranskrypcje.pl">
                        biuro@protranskrypcje.pl
                    </SLink>{' '}
                    w celu dokonania wyceny lub sprobuj dodać inny plik.
                </SText>
            ) : (
                <SText>
                    Twoje nagrania przekraczają 2 godziny. Skontaktuj się z nami
                    mailowo{' '}
                    <SLink href="mailto:biuro@protranskrypcje.pl">
                        biuro@protranskrypcje.pl
                    </SLink>{' '}
                    w celu indywidualnej wyceny i dodatkowej zniżki!
                </SText>
            )}
        </SPopup>
    )
}
