import React, { useEffect } from 'react'
import { Input, Checkbox } from '../../../../../components'
import { FORM_FIELDS } from '../../../../../constants'
import styled from 'styled-components'
import { useInputProps } from '../../../../../hooks'
import { useFormContext } from 'react-hook-form'

const SFormWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SInputRow = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.lg}) {
        flex-direction: column;
    }
`

const SAddressWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-grow: 1;
    flex-basis: 100%;
`

const SPostCodeInput = styled(Input)`
    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-basis: 33%;
    }
`

export const ReceiverData = () => {
    const { watch, register, trigger, formState } = useFormContext()
    const getInputProps = useInputProps()
    const includeReceiverNip = watch(FORM_FIELDS.includeReceiverNip)

    useEffect(() => {
        if (formState.isSubmitted) trigger()
    }, [includeReceiverNip])

    return (
        <SFormWrapper>
            <Checkbox
                id="receiverNipCheckbox"
                label="Chcę podać NIP odbiorcy"
                {...register(FORM_FIELDS.includeReceiverNip)}
            />

            {includeReceiverNip && (
                <Input
                    {...getInputProps(FORM_FIELDS.receiverNip)}
                    placeholder="NIP*"
                />
            )}

            <Input
                {...getInputProps(FORM_FIELDS.receiverCompanyName)}
                placeholder="Firma/Podmiot*"
            />
            <SInputRow>
                <Input
                    {...getInputProps(FORM_FIELDS.receiverStreet)}
                    placeholder="Ulica*"
                />
                <SAddressWrapper>
                    <Input
                        {...getInputProps(FORM_FIELDS.receiverHouseNumber)}
                        placeholder="Numer domu*"
                    />
                    <Input
                        {...getInputProps(FORM_FIELDS.receiverFlatNumber)}
                        placeholder="Numer lokalu"
                    />
                </SAddressWrapper>
            </SInputRow>
            <SInputRow>
                <SPostCodeInput
                    {...getInputProps(FORM_FIELDS.receiverPostCode)}
                    placeholder="Kod pocztowy*"
                />
                <Input
                    {...getInputProps(FORM_FIELDS.receiverCity)}
                    placeholder="Miejscowość*"
                />
            </SInputRow>
        </SFormWrapper>
    )
}
