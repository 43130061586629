import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SectionWrapper } from '../../components'
import { CLIENT_LABEL, CLIENT_TYPE, FORM_FIELDS } from '../../../../constants'
import { RadioButton } from '../../../../components'
import { Controller, useFormContext } from 'react-hook-form'
import { RADIO_VARIANT } from '../../../../components/radio-button/style'
import { BusinessClient } from './business-client'
import { IndividualClient } from './individual-client'

const SOptionsWrapper = styled.div`
    display: flex;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.lg}) {
        flex-direction: column;
    }
`
const SRadioButton = styled(RadioButton)`
    &:last-child {
        flex-grow: 1;
    }
`

export const ClientDataSection = () => {
    const { control, watch, resetField, trigger, formState } = useFormContext()
    const clientType = watch(FORM_FIELDS.clientType)

    useEffect(() => {
        if (clientType === CLIENT_TYPE.individual) {
            resetField(FORM_FIELDS.nip)
            resetField(FORM_FIELDS.country)
            resetField(FORM_FIELDS.includeReceiverDataOnInvoice)
        }

        if (formState.isSubmitted) {
            trigger()
        }
    }, [clientType])

    return (
        <SectionWrapper title="Dane klienta">
            <Controller
                control={control}
                name={FORM_FIELDS.clientType}
                render={({ field: { onChange, value } }) => {
                    return (
                        <SOptionsWrapper>
                            {[CLIENT_TYPE.individual, CLIENT_TYPE.business].map(
                                (option) => (
                                    <SRadioButton
                                        id={option}
                                        name={FORM_FIELDS.clientType}
                                        label={CLIENT_LABEL[option]}
                                        variant={RADIO_VARIANT.underline}
                                        value={option}
                                        checked={value === option}
                                        onChange={onChange}
                                    />
                                )
                            )}
                        </SOptionsWrapper>
                    )
                }}
            />

            {clientType === CLIENT_TYPE.business ? (
                <BusinessClient />
            ) : (
                <IndividualClient />
            )}
        </SectionWrapper>
    )
}
