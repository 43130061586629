import React, { useState } from 'react'
import { Recording } from '../../../../components'
import styled from 'styled-components'
import { useWizard } from '../../../../context'
import { DeleteFileModal } from '../delete-file-modal'
import { useFormContext } from 'react-hook-form'
import { FORM_FIELDS } from '../../../../constants'

const SWrapper = styled.ul`
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: ${({ theme: { colors } }) => colors.gray_2};
    }

    &::-webkit-scrollbar-thumb {
        background: ${({ theme: { colors } }) => colors.primary_darker};
    }
`

export const FileList = ({ files }) => {
    const [fileToDelete, setFileToDelete] = useState(null)
    const { deleteFile, updateFile } = useWizard()
    const { trigger } = useFormContext()

    const handleDelete = () => {
        deleteFile(fileToDelete)
        setFileToDelete(null)
        trigger(FORM_FIELDS.files)
    }

    return (
        <>
            <SWrapper onClick={(e) => e.stopPropagation()}>
                {files.map((item) => (
                    <Recording
                        key={item.tempId}
                        {...item}
                        onUpdateDuration={(duration) => {
                            updateFile({
                                tempId: item.tempId,
                                duration,
                                analysisReady: true,
                            })
                        }}
                        onFinishProcessing={() =>
                            updateFile({
                                tempId: item.tempId,
                                analysisReady: true,
                            })
                        }
                        onDelete={() => setFileToDelete(item)}
                    />
                ))}
            </SWrapper>
            {fileToDelete && (
                <DeleteFileModal
                    onDelete={handleDelete}
                    onClose={() => setFileToDelete(null)}
                />
            )}
        </>
    )
}
