import React, { useEffect, useState } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { theme } from '../../../theme'
import { OrderSummary } from '../sections'
import { STEPS } from '../../../constants'
import { ErrorMessage } from '../../error/components'
import { useWizard } from '../../../context'
import { ENDPOINTS, axiosInstance } from '../../../api'

export const OrderContainer = ({ orderId }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const { setStepError } = useWizard()

    const fetchData = async () => {
        try {
            setLoading(true)

            const { data } = await axiosInstance.get(
                ENDPOINTS.CLIENT_ORDER_DETAILS(orderId)
            )

            if (data.errorCode > 300) throw new Error()
            setData(data)
        } catch {
            setStepError(STEPS[2])
            setError(true)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (loading)
        return (
            <ScaleLoader
                color={theme.colors.secondary}
                width={6}
                margin={3}
                radius={4}
                height={60}
            />
        )

    if (error || !data)
        return (
            <ErrorMessage
                orderId={orderId}
                text="Wystąpił błąd przy pobieraniu szczegółów zamówienia."
            />
        )

    return <OrderSummary orderId={orderId} data={data} />
}
