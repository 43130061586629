import React from 'react'
import { Mail, SectionWrapper } from '../../components'
import styled from 'styled-components'
import { CheckIcon } from '../../../../icons'
import { CLIENT_TYPE, COMPLETION_TIME_LABEL } from '../../../../constants'

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`

const SHeader = styled.h2`
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 20px;
    }
`

const SOrder = styled.span`
    color: ${({ theme: { colors } }) => colors.primary};
`

const SBold = styled.p`
    font-weight: 600;
`

const SSeparator = styled.hr`
    width: 100%;
    border: 1px solid ${({ theme: { colors } }) => colors.gray_1};
    margin: 16px 0;
`

const SText = styled.p`
    margin-bottom: 16px;
`

const SCheckIcon = styled(CheckIcon)`
    height: 24px;
    width: 24px;
`

const SRow = styled.div`
    display: flex;
    gap: 8x;
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;
    }
`

export const OrderSummary = ({ data, orderId }) => {
    const {
        createdAt,
        name,
        lastName,
        email,
        phoneNumber,
        postCode,
        houseNumber,
        street,
        flatNumber,
        nip,
        companyName,
        completionTime,
        clientType,
        buyerStreet,
        buyerHouseNumber,
        buyerFlatNumber,
        buyerPostCode,
        buyerCity,
        buyerNip,
        buyerCompanyName,
        buyerName,
        buyerLastName,
        price,
        city,
    } = data

    const address = buyerStreet
        ? `${buyerStreet} ${buyerHouseNumber} ${
              buyerFlatNumber ? 'm. ' + buyerFlatNumber : ''
          }`
        : `${street} ${houseNumber} ${flatNumber ? 'm. ' + flatNumber : ''}`

    return (
        <SWrapper>
            <SectionWrapper>
                <SHeader>
                    <SCheckIcon />
                    <span>
                        Podsumowanie zamówienia nr <SOrder>#{orderId}</SOrder>
                    </span>
                </SHeader>
                <SSeparator />
                <SText>
                    Drogi Kliencie, dziękujemy za zaufanie naszemu zespołowi i
                    złożenie zamówienia!
                    <br />
                    <br />
                    Przyjęliśmy Twoje zlecenie do realizacji i już niedługo
                    otrzymasz od nas profesjonalny stenogram.
                    <br />
                    <br />
                    Fakturę VAT prześlemy na maila podanego przy składaniu
                    zamówienia.
                    <br />
                    <SSeparator />
                    Jeśli masz jakieś pytania, zachęcamy do kontaktu mailowego:
                </SText>
                <Mail />
                <br />
                <SBold>Dobrego dnia!</SBold>
            </SectionWrapper>
            <SectionWrapper>
                <SHeader>Podstawowe informacje o zamówieniu:</SHeader>
                <SSeparator />
                <SRow>
                    <span>Numer zamówienia:</span>
                    <SBold>{orderId}</SBold>
                </SRow>
                <SRow>
                    <span>Data stworzenia zamówienia:</span>
                    <SBold>{new Date(createdAt).toLocaleDateString()}</SBold>
                </SRow>
                {clientType === CLIENT_TYPE.business ? (
                    <SRow>
                        <span>Podmiot składający zamówienie:</span>
                        <SBold>{companyName}</SBold>
                    </SRow>
                ) : (
                    <SRow>
                        <span>Osoba składająca zamówienie:</span>
                        <SBold>{`${name} ${lastName}`}</SBold>
                    </SRow>
                )}

                <SRow>
                    <span>Email kontaktowy</span>
                    <SBold>{email}</SBold>
                </SRow>
                <SRow>
                    <span>Telefon kontaktowy:</span>
                    <SBold>{phoneNumber || '-'}</SBold>
                </SRow>
            </SectionWrapper>
            <SectionWrapper>
                <SHeader>Dane do faktury:</SHeader>
                <SSeparator />
                <SRow>
                    <span>Typ rachunku</span>
                    <SBold>
                        Faktura{' '}
                        {clientType === CLIENT_TYPE.business && <>(firma)</>}
                    </SBold>
                </SRow>
                {clientType === CLIENT_TYPE.business ? (
                    <>
                        <SRow>
                            <span>Nazwa</span>
                            <SBold>{buyerCompanyName || companyName}</SBold>
                        </SRow>
                        <SRow>
                            <span>NIP:</span>
                            <SBold>{buyerNip || nip}</SBold>
                        </SRow>
                    </>
                ) : (
                    <>
                        <SRow>
                            <span>Imię</span>
                            <SBold>{buyerName || name}</SBold>
                        </SRow>
                        <SRow>
                            <span>Nazwisko</span>
                            <SBold>{buyerLastName || lastName}</SBold>
                        </SRow>
                    </>
                )}
                <SRow>
                    <span>Ulica</span>
                    <SBold>{address}</SBold>
                </SRow>
                <SRow>
                    <span>Kod pocztowy</span>
                    <SBold>{buyerPostCode || postCode}</SBold>
                </SRow>
                <SRow>
                    <span>Miejscowość</span>
                    <SBold>{buyerCity || city}</SBold>
                </SRow>
            </SectionWrapper>
            <SectionWrapper>
                <SHeader>Wybrany termin realizacji</SHeader>
                <SSeparator />
                <SBold>{COMPLETION_TIME_LABEL[completionTime]}</SBold>
            </SectionWrapper>
            <SectionWrapper>
                <SHeader>Całkowita kwota do zapłaty</SHeader>
                <SSeparator />
                <SRow>
                    <span>Całkowita kwota do zapłaty</span>
                    <SBold>{price} zł z VAT</SBold>
                </SRow>
                {/* TODO: info o platnosci */}
                {/* <SRow>
                    <span>Realizacja płatności</span>
                    <SBold>BLIK</SBold>
                </SRow> */}
            </SectionWrapper>
        </SWrapper>
    )
}
