import React, { useMemo, useState } from 'react'
import { UploadButton, Error as ErrorMessage } from '../../../../components'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import {
    EXTENDED_FORMATS,
    ACCEPTED_FORMATS,
    MAX_SIZE,
} from '../../../../constants'
import { FileIcon } from '../../../../icons'
import { formatSize } from '../../../../utils/format-size'
import { FileList } from '../file-list'
import { Captcha } from '../captcha'
import { useWizard } from '../../../../context'
import { getExtension } from '../../../../utils/get-extension'

const getBorderColor = ({
    theme: { colors },
    isDragActive,
    filesSelected,
    error,
}) => {
    if (isDragActive) return colors.primary

    if (error) return colors.error

    if (filesSelected) return colors.secondary_darker

    return colors.gray_2
}

const SWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.gray_1_lighter};
    border-radius: 5px;
    border: 2px solid ${getBorderColor};
    padding: 20px 10px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        border-radius: 10px;
        gap: 20px;
    }
`

const SInnerWrapper = styled.div`
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        gap: 20px;
    }
`

const SInput = styled.input`
    width: 0px;
    height: 0px;
`

const SSelectText = styled.p`
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        font-size: 20px;
        margin-bottom: 15px;
    }
`

const SFileIcon = styled(FileIcon)`
    width: 68px;
    height: 68px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.md}) {
        width: 90px;
        height: 90px;
    }
`

const SRequirements = styled.p`
    color: ${({ theme: { colors } }) => colors.gray_3};
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
`

const SErrorMessage = styled(ErrorMessage)`
    align-self: flex-start;
`
const SBold = styled.span`
    font-weight: 600;
    color: inherit;
`

const SMessage = styled.p`
    color: inherit;
`

export const Dropzone = ({ onDrop, invalid, value }) => {
    const [rejectedFiles, setRejectedFiles] = useState([])

    const errorMessage = useMemo(() => {
        switch (rejectedFiles.length) {
            case 0:
                return ''
            case 1:
                const { file, errors } = rejectedFiles[0]

                return (
                    <SMessage>
                        Wgrywanie pliku {file.name} nie powiodło się:{' '}
                        <SBold>{errors[errors.length - 1]?.message}</SBold>
                    </SMessage>
                )
            default:
                return (
                    <SMessage>
                        Wgrywanie plików:{' '}
                        <SBold>
                            {rejectedFiles
                                .map(({ file }) => file.name)
                                .join(', ')}
                        </SBold>{' '}
                        nie powiodło się.
                        <br />
                        Sprawdź wymagania i spróbuj ponowanie.
                    </SMessage>
                )
        }
    }, [rejectedFiles])

    // TODO: handle errors
    const validator = (file) => {
        const { size } = file
        const extension = getExtension(file)

        if (size === 0) {
            return new Error('ten plik jest pusty.')
        }

        if (size > MAX_SIZE) {
            return new Error('plik przekracza dozwolną wielkość.')
        }

        if (!EXTENDED_FORMATS.includes(extension)) {
            return new Error('format pliku jest nieprawidłowy')
        }

        return null
    }

    const { captchaSolved } = useWizard()

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        disabled: !captchaSolved,
        onDrop: (accepted, rejected) => {
            onDrop(accepted)
            setRejectedFiles(rejected)
        },
        validator,
    })

    return (
        <SWrapper
            {...getRootProps()}
            error={invalid}
            isDragActive={isDragActive}
            filesSelected={value?.length > 0}
            onClick={(e) => e.preventDefault()}
        >
            <SInput {...getInputProps()} />
            {value?.length > 0 ? (
                <FileList files={value} />
            ) : (
                <SInnerWrapper>
                    <SFileIcon />
                    <SSelectText>
                        Wybierz lub upuść pliki, do których potrzebujesz
                        stenogramów
                    </SSelectText>
                </SInnerWrapper>
            )}
            <SInnerWrapper>
                <UploadButton onClick={open} />
                <SRequirements>{`Max wielkość jednego pliku: ${formatSize(
                    MAX_SIZE
                )}. Dozwolone formaty: ${ACCEPTED_FORMATS.map((format) =>
                    format.toUpperCase()
                ).join(', ')}`}</SRequirements>
                {errorMessage && <SErrorMessage error={errorMessage} icon />}
            </SInnerWrapper>
            <Captcha />
        </SWrapper>
    )
}
