import React from 'react'
import styled from 'styled-components'
import { buttonStyle, BUTTON_STYLES, BUTTON_VARIANTS } from './style'

const SButton = styled.button`
    ${buttonStyle};

    ${({ variant }) => BUTTON_STYLES[variant]}
`

export const Button = ({
    className,
    variant,
    children,
    type = 'button',
    ...props
}) => {
    return (
        <SButton
            variant={variant || BUTTON_VARIANTS.fill}
            className={className}
            type={type}
            {...props}
        >
            {children}
        </SButton>
    )
}
